.privacyPolicyContainer {
  padding-top: 20px;
  font-weight: 400;
}

.privacyPolicyTitleContainer {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.title {
  font-size: 40px;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.policyParagraph {
  width: 550px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #6d6767;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.policyTextContainer {
  text-align: left;
  margin-left: 30px;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 0;
}
