.containerSelect {
	position: relative;
	box-sizing: border-box;
	display: inline-block;
	width: 31%;
	min-width: 320px;
	height: 60px;
	border: solid 1px;
	border-color: #EDF1F5;
	border-radius: 4px;
	margin: 10px;
	padding: 0px 30px;
	background-color: #EDF1F5;
	;
}

.containerSelect:hover {
	cursor: pointer;
}
.containerSelectHighlight {
	position: relative;
	box-sizing: border-box;
	display: inline-block;
	background-color: #4bace9;
	width: 31%;
	min-width: 320px;
	height: 60px;
	border: solid 1px;
	border-color: transparent;
	border-radius: 4px;
	margin: 10px;
	padding: 0px 30px;
}

.containerSelectHighlight:hover {
	cursor: pointer;
}
.candidateProfilePic {
	position: absolute;
	left: 30px;
	top: 50%;
	transform: translateY(-50%);
	height: 50px;
	width: 50px;
	border-radius: 50%;
}

.candidateCheckbox {
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
	background-color: none;

	height: 18px;
	width: 18px;
}

.candidateInfo {
	position: absolute;
	left: 90px;
	top: 50%;
	transform: translateY(-50%);
	margin: 0px;
	text-align: left;
	line-height: 20px !important;
}

.candidateName {
	font-size: 18px;
	font-weight: bold;
}

.candidateTitle {
	font-size: 14px;
}
