/* These styles are used if a demo specific stylesheet is not present */
.paymentWrapper{

    min-height: 500px;
    position: relative;
}
.paymentWrapper form {
  max-width: 600px;
  margin: 80px auto;
  background-color: #021939;
  border-radius: 30px;
  padding:20px;
}
.paymentWrapper .FormGroup {
    border: 0px;
    

}

.paymentWrapper label {
    width: 530px;
  color: #6b7c93;
  font-weight: 300;
  margin: auto;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;

    font-size: 16px;

    color: rgba(255, 255, 255, 0.87);
}

.paymentWrapper form button {
  white-space: nowrap;
  border: 0;
  outline: 0;

  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #4bace9;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
    margin:10px 34px;
  width: 530px;
  
}

.paymentWrapper form button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #4bace9;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.paymentWrapper form button[disabled] {
  opacity: 0.6;
}

.paymentWrapper input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px auto;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
}

.paymentWrapper input::placeholder {
  color: #aab7c4;
}


.paymentWrapper .error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.paymentWrapper .error {
  color: #e4584c;
}


/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal {
    margin: 10px auto;
  display: block;

  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal {
  padding: 0;
}







.paymentWrapper .Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
  }
  
  .ResultTitle {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
  }
  
  .ResultMessage {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
  }
  
  .ResetButton {
    border: 0;
    cursor: pointer;
    background-color: #4bace9;
    border-radius: 3px;
  }

