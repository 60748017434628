html {
	font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	overflow-x: hidden;
}

span.clear {
	clear: left;
	display: block;
}

.experience{
	display: flex;
	flex-direction: column;
}

.experience .item{
	display: flex;
	flex-direction: row;
	overflow: visible;
}

.chevron{
	transition: 0.5s
}

.profileContainer{
	background-color: white;
	flex: 100%;
	padding: 30px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	min-height: 400px;
	z-index: 0;
	border-radius: 4px;
}

.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
  }
  
  .blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
  }
  
  .blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
  }

  .profileTop{
 margin: 25px 0px;
 display: flex;
 width:100%;
  }

.profileImage{
	border-radius: 50%;
	width:200px;
	
}

.profileInfo{
	margin-left: 25px;
}

.profileInfo h1{
	margin-top: 35px;
}

.profileActions{
	margin-left: auto;
}

.hiringButton{
	padding: 5px 10px;
margin-top: -5px;
	background: #4BACE9;
	border: 1px solid #4BACE9;
	box-sizing: border-box;
	border-radius: 4px;
	color:white
}

.favorite {
	font-size: 35px !important; 
	margin-left:10px !important;
	position: static !important;
	transform:translateY(0%) !important;
	
}
.gray {
	color: #cccccc;
	
}

.gold {
	color: #f6d832;
}


.favorite:hover {
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #4bace9;
}




.profileBottom{
	width:100%;
	min-height: 300px;
	display: flex;
}

.profileResume{
	flex: 45%;
	margin-right: 4%;
	
}

.profileResume h2{
	margin-top: 20px;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
}

.company{
	white-space: initial;
}

.profileResume p{
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;

	display:inline-block;
	clear:both;

	padding:10px 15px;

	background-color: none;
	border-radius: 10px;
	color: #738094;

}


.mentorContainer{
	display:flex;
}

.mentorProfile{
	border-radius: 50%;
	width:60px;
}

.text_mentor{
	margin-left: 10px;
}

.mentor p{
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0em;
	padding: 0;
	text-align: left;
	color: #738094;
	background-color:#ffffff;
}

.text_mentor h4{
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
	color: #738094;

}

.text_mentor h5{
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
	color: #738094;

}




.fadeOut_experience {
	opacity: 0;
	width: 0;
	height: 0;
	transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.1s;
}
.fadeIn_experience {
	opacity: 1;
	width: 100%;
	transition: width 0.2s, height 0.5s, opacity 0.1s 0.1s;
}


.videoButton {
	border-radius: 5px 5px 0px 0px;
	border:0px;
	background-color: #4bace9;
	color: white;
	height: 30px;
	margin-top: 20px;
	margin-right: 5px;
}

/*profileGrades -----------------------------------------*/

.profileGrades{
	flex: 20%;
	margin-right: 3%;
	
}

.profileGrades h2{
	margin-top: 20px;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
}
.grade{
	display:flex;
	margin: 20px 0px;
	
}

.gradeIconReact{
	width: 45px;

}
.gradeIconJava{
	width: 60px;

}
.gradeIconCyber{
	width: 35px;

}
.gradeInfo{
	text-align: left;
	margin-left: 15px;
	
}

/*profileSkills -----------------------------------------*/

.profileSkills{
	flex: 28%;
	
}

.profileSkills h2{
	margin-top: 20px;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
}

.profileSkills h4{
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0.02em;
	text-align: left;
	color:#8A8BA1;
	margin-bottom: 0;
	margin-top: 40px;

}

.skillBlock{
	width:100%;
	margin-top: 10px;
	margin-left:-8px;
	overflow: auto;

}

.skill{
font-size: 20px;



padding: 8px 16px;
border-radius: 10px;
color: #738094;
margin: 8px;
position:relative;
float: left;
width:fit-content;

background-color: #EDF1F5;
;


}

.rotate{
	transform: rotate(90deg);
	transition: 0.5s;
	align-self: center;
}
.normal{
	transform: rotate(0deg);
	transition: 0.5s;
	align-self: center;
}
