.educationForm{

}

.educationForm h3{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    color: #4b5566;
}


.educationInput{
    margin-bottom:10px;
    color:  #4b5566;
	width:100% !important;

}

.educationInputL{
    margin-bottom:10px;
    color:  #4b5566;
	width:calc(50% - 5px) !important;
	margin-right: 10px
}

.educationInputR{
    margin-bottom:10px;
    color:  #4b5566;
	width:calc(50% - 5px) !important;

}

.educationLabelL{
    margin-bottom:10px;
    margin-top: 10px;
    text-align: left;
	width:calc(50% - 5px) !important;
	margin-right: 10px
}

.educationLabelR{
    margin-bottom:10px;
    text-align: left;
	width:calc(50% - 5px) !important;

}