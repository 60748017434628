.left {
	//width: 14vw;
	width: 211px;
	max-width: 244.5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: #FFFFFF;
	transition: width 600ms ease;
	z-index: 2;
	position:relative;
	box-shadow: rgba(0,0,0,0.1) 2px 4px 4px;
}

.leftCollapse {
	width: 60px;
	max-width: 244.5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	background-color: #FFFFFF;
	transition: width 600ms ease;
	z-index: 2;

	box-shadow: rgba(0,0,0,0.1) 2px 4px 4px;
}

.collapseButton{
	position:absolute;
	right:0;
	top:0;
	margin:10px;
}

.navbarNav {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	padding-bottom: 25px;
}

.hide{
	display:none;
}


.headerContainer {
	padding:0px;
	width: 100%;
	padding-top:30px;
	
  }

  .headerName{
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: center;
	color:  #4B5566;
	
  }
.imgWrapper{
	display:flex;
}
  .headerPosition{
	font-size: 12px;
	font-style: italic;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
	text-align: center;
	color:  #4B5566;
	
  }

.header_img {
	text-align: center;
	margin: auto;
	width: 60px;
	height: 60px !important;
	max-height: 60px !important;
	border-radius: 50%;
  }



.navItem {
	width: 100%;
	margin: 20px 0px;
	border-radius: 5px;
	padding:0px 15px;
}

a{
	text-decoration: none;
}

.navItem:hover {
	background-color: #EDF1F5;
	
}

.navItem:hover .linkText {
	text-decoration: none;
}

.navLink {
	display: flex;
	align-items: center;
	height: 3rem;
	text-decoration: none;
}

.linkText {
	color:  #4B5566;
	margin:0px;
	text-decoration: none;
	padding: 5px;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0em;
	margin-left: 10px;

}

.navLink img {
	width: 1.2rem;
	
}





//auto expand
/*
.candidate_process .col-container .left:hover {
	width: 18vw;
}


.candidate_process .col-container .left:hover .link-text {
	display: block;
}

*/




// Old dahsboard styling

/*
.candidate_process .left_collapse {
	display: none;
	height: 100%;
	width: 27%;
	min-height: inherit;
	float: left;
	margin-right: 0px;
	margin-top: 0px;
}

.candidate_process .left .container1 {
	position: relative;
	margin-right: 0px;
	text-align: left;
	margin-top: 0px;
	min-height: inherit;
}

.candidate_process .col-container .dash_collapse_button {
	position: absolute;
	bottom: 5px;
	left: 0px;
	z-index: 1;

	background-color: #021939;
	border: 1px solid #ffffff;
	border-left: transparent;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;

	width: 40px;
	height: 45px;

	cursor: pointer;
}

.stay_button_sticky {
	position: -webkit-sticky; /* Safari 
	position: sticky;
	height: 5px;
	top: 95%;
	z-index: 2;
}

.stay_button {
	position: absolute;
	left: 0px;
	bottom: 0px;
	/*z-index: 99;

	background-color: #021939;
	border: 1px solid #ffffff;
	border-left: transparent;
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;

	width: 40px;
	height: 45px;

	cursor: pointer;
}

.collapse_arrow_left {
	width: 15px;
	height: 15px;
}

.collapse_arrow_right {
	width: 15px;
	height: 15px;
	transform: rotate(180deg);
}

.candidate_process .left .dash_background {
	width: 100px;
	height: 100%;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 5px;
	background: #021939;
}

.candidate_process .dash_background .sticky {
	position: -webkit-sticky; /* Safari 
	position: sticky;
	top: 80px;
}
*/

/*
.dash_button {
	border: none;
	background-color: transparent;
	outline: none;

	width: 100%;
	height: 90px;
	margin: 5px 0px;

	/*border: 1px solid #FFFFFF;
	display: inline-block;

	background-color: #021939;

	vertical-align: middle;

	text-align: center;
	color: antiquewhite;

	cursor: pointer;
}

.dash_button:hover {
	text-decoration: underline;
	/*background-color: #3E92CB;*/
/* filter: hue-rotate(180deg); 
	text-decoration-color: #4bace9;
	color: #4bace9;
	cursor: pointer;
}

.dash_button img {
	width: 35px;
	height: 35px;
}

/*.logout{
    margin-top: 80px;
}

.dash_button h3 {
	margin-top: 2px;
	font-weight: normal;
	font-size: 13px;
	line-height: 18px;
}

logout -------------------------
*/
.logoutSection {
	display: none;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: transparent;
	backdrop-filter: blur(5px);
	color: #f2f2f2;
	position: fixed;
	z-index: 6;
	animation: fadeIn ease 0.3s;
}

.logoutBox {
	display: none;
	-ms-flex-direction: column;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	left: calc(50% - 15em);
	top: calc(50vh - 300px);
	min-width: 30em;
	min-height: 300px;

	background-color: white;
	color: #4B5566;
	border-radius: 4px;
	z-index: 6;
	position: fixed;
	border: 1px solid #888;
	animation: fadeIn ease 0.3s;
}

.logoutButtons {
	margin-top: 70px;
	display: flex;
}

.logoutButtons button {
	width: 150px;
	height: 30px;
	margin: 10px;
	border-radius: 30px;
	display: inline-block;

	vertical-align: middle;
border-radius: 4px;
	text-align: center;
	border: 1px solid #888;
	background-color: #EDF1F5;
	
	color: #4B5566;
}
