// @import '~mdb-ui-kit/css/mdb.min.css';
// @import 'fortawesome/fontawesome-free/css/all.min.css'; 
// @import 'bootstrap-css-only/css/bootstrap.min.css'; 
// @import 'mdbreact/dist/css/mdb.css';
.fa-chevron-left{
	display: none !important;
}

.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
  }
  
  .blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
  }
  
  .blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
  }

.inner {
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 1;
  }


.filterContainer {
	background-color: #edf1f5;
	border-radius: 4px;
	padding: 25px;

	/* new design*/
	background-color: #ffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
  }
  
  .blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
  }
  
  .blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
  }


.message_list {
	background-color: white;
	flex: 75%;
	margin-right: 2.5%;
	padding: 24px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}

// .table {
// 	border: 1px solid black;
// }
