.inner {
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 1;
  }

.candidate_filter {
	flex: 20%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.filterContainer {
	background-color: #edf1f5;
	border-radius: 4px;
	padding: 25px;

	/* new design*/
	background-color: #ffff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
  }
  
  .blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
  }
  
  .blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
  }


.candidates_list {
	background-color: white;
	flex: 75%;
	margin-right: 2.5%;
	padding: 24px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}

.course_type {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	text-transform: capitalize;
	margin-right: 6px;

	color: #000000;
}

.button {
	position: absolute;
	background: #c4c4c4;
}

.candidate_filter_elements {
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 24px;
	text-transform: capitalize;
	color: #000000;
	margin-bottom: 2px;
	margin-right: 6px;
}

.typeButton:hover {
	cursor: pointer;
}

.dropdownListOpen {
	animation: fade-down ease 0.6s;
}

.dropdownListClose {
	animation: fade-down ease 0.5s;
}

@keyframes fade-down {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.dropdownCheckList {
	margin-bottom: 3px;
}

.checkbox_class {
	background: #ffffff;
	border: 1px solid #4bace9;
	box-sizing: border-box;
	margin-right: 10px;
}
