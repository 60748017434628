html {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

hr {
  visibility: hidden;
  margin: 0px;
}

/* Hero section ----------------- */

.heroHome {
  background-image: url("../../assets/backgrounds/homeBackground.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 1px;
  min-height: 714px;
  background-size: cover;
  text-align: center;
}

.heroHome .container {
  text-align: left;
  color: white;
  margin: 0px;

  margin-left: 120px;
  margin-top: 241px;
}

.heroHome .container .c1 {
  animation: fadeIn ease 2s, moveRight 1.5s;
}

.heroHome .container .c2 {
  animation: fadeIn2 ease 2s, moveRight 1.8s;
}

.heroHome .container .c1 h3 {
  margin: 0px;
  font-size: 52px;
  line-height: 130.1%;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.01em;
}

.heroHome .container .c1 h2 {
  margin: 0px;
  font-size: 52px;
  line-height: 130.1%;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.01em;
}

.heroHome .container p {
  animation-delay: 0.5s;
  margin-top: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  color: white;
  max-width: 560px;
}

/* apply now section ----------------- */

.applyNow {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.12);
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 115px;
  background-color: #142a49;
  text-align: center;
  border-radius: 30px;
  width: 65%;
  min-width: 996px;
  min-height: 100px;
  padding-top: 15px;
}
.applyNow p {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 100%;
  margin: 8px;
  margin-top: 18px;
  color: #e4f4fe;
}
.applyNow form {
  margin: auto;
  margin-top: 16px;
  margin-bottom: 18px;
  width: 100%;
}

.applyNow input {
  box-sizing: border-box;
  font-size: 18px;
  line-height: 140%;
  color: #4b5566;
  width: 20%;
  height: 54px;

  padding: 10px;
  border-radius: 3px;
  border: 0px;
  margin: 0.4%;
  margin-bottom: 3px;
}

.applyNow select {
  font-size: 18px;
  line-height: 140%;
  color: #4b5566;
  width: 20%;
  height: 54px;
  margin-bottom: 6px;
  padding: 10px;
  border-radius: 3px;
  border: 0px;
  margin: 0.4%;
  margin-bottom: 5px;
}

.applyNow button {
  color: white;
  padding-left: 25px;
  margin-top: 10px;
  border-radius: 3px;
  height: 54px;
  border: 1px solid #4bace9;
  background-color: #4bace9;
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  text-align: right;
  letter-spacing: 0.05em;
  border-radius: 30px;
  margin: 0.4%;
  margin-top: 5px;
  margin-bottom: 3px;
}

.applyNow button span {
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: 0.5s;
}
.applyNow button span:after {
  content: " \00bb";
  position: relative;
  opacity: 0;
  right: -10px;
  transition: 0.5s;
}

.applyNow button:hover span:after {
  opacity: 1;
  right: 0;
}
/* our users section ----------------- */

.userContainer {
  display: flex;
  width: 100%;
  min-height: 450px;
  text-align: center;
}
.userCol {
  flex: 1;
  position: relative;
  width: 33.33%;
  padding: 10px;
  padding: 1px;
  border: 1px solid white;
}

.userCol .container {
  margin: 47px;
  margin-bottom: 100px;
  margin-top: 100px;
}

.userCol h3 {
  margin: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-transform: capitalize;
  color: #2f3747;
}

.userCol p {
  margin: 0px;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 150%;
  color: #4b5566;
}

.userCol button {
  position: absolute;
  left: 36%;
  text-align: center;
  bottom: 0px;
  padding: 12px 28px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  font-weight: bold;
  border-radius: 32px;
  border: 1px solid #4bace9;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #4bace9;
  margin-bottom: 30px;
}

/* our curriculum  section ----------------- */

.ourCurriculum {
  padding: 60px 10px;
}

.ourCurriculum .container-main {
  text-align: center;
  margin-bottom: 40px;
}

.ourCurriculum .container-main h1 {
  margin: 10px;
  font-size: 38px;
  line-height: 46px;
  font-style: normal;
  font-weight: bold;
}
.ourCurriculum .container-main p {
  margin: 10px;
  font-size: 20px;
  line-height: 130%;
  color: #738094;
}

.benefitsRow {
  width: 100%;
  content: "";
  display: table;
  clear: both;
}
.benefitsCol1 {
  float: left;
  width: 50%;
}
.benefitsCol2 {
  float: right;
  width: 50%;
}

.benefitsRow .container {
  overflow: auto;
  display: flex;
  margin: 30px;
}

.benefitsRow .image {
  margin: 0px;
  margin-top: 10px;
  margin-right: 18px;
  max-height: 64px;
}
.benefitsCol1 .container {
  float: right;
  max-width: 520px;
}
.benefitsCol2 .container {
  float: left;
  max-width: 520px;
}

.ourCurriculum .container p {
  margin: 8px 0px;
  font-size: 18px;
  line-height: 140%;
  color: #738094;
}

.ourCurriculum .container h3 {
  margin: 8px 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
}

/* testimonials section ----------------- */

.sectionTestimonials {
  padding: 1px;
  background-color: #053654;
  min-height: 660px;
}

.sectionTestimonials .container {
  margin: 20px;
  margin-top: 99px;
  margin-bottom: 60px;
  text-align: center;
}

.sectionTestimonials h1 {
  margin: 0px;
  font-size: 38px;
  line-height: 46px;
  color: white;
}
.sectionTestimonials p {
  margin: 0px;
  font-size: 20px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.87);
}
.testimonials {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial {
  border-radius: 2px;
  margin: 4px;
  background-color: white;
  height: 332px;
  max-width: 387px;
  padding: 1px;
}

.testimonial .person {
  text-align: left;
  margin: 24px;
}

.testimonial img {
  border-radius: 2px;
  margin-right: 10px;
  float: left;
}

.testimonial hr {
  width: 100%;
  margin: 0px;
  margin-bottom: 20px;
}

.profile {
  min-height: 120px;
  position: relative;
}

.info {
  position: absolute;
  bottom: 0px;
  left: 130px;
}

.testimonial h5 {
  margin: 0px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #1c1c1c;
}

.testimonial h6 {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #4bace9;
}

.testimonial p {
  margin: 0px;
  text-align: left;
  font-size: 18px;
  line-height: 130%;
  color: #738094;
}

/* contact us section ----------------- */

.contactUsHome {
  background-image: url("../../assets/backgrounds/Rectangle 46.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 648px;
  background-size: cover;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 80vh;
}
.contactUsLeft {
  width: 50%;
  padding: 50px;
  padding-right: 10px;
}
.contactUsRight {
  padding: 50px;
  padding-left: 10px;
  width: 50%;
  display: flex;
}
.contactUsHome .applyNow2 {
  margin: auto 10%;
  float: left;
  margin-left: 300px;
}

.contactUsHome .applyNow2 h1 {
  margin: 0px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 31px;
  line-height: 46px;
  color: #ffffff;
}

.contactUsHome .applyNow2 button {
  margin-top: 30px;
  color: white;
  width: 170px;
  cursor: pointer;
  border-radius: 30px;
  height: 53px;
  border: 0px;
  background-color: #4bace9;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.04em;
}

.contactUsHome .applyNow2 button span {
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: 0.5s;
}
.contactUsHome .applyNow2 button span:after {
  content: " \00bb";
  position: relative;
  opacity: 0;
  right: -10px;
  transition: 0.5s;
}

.contactUsHome .applyNow2 button:hover span:after {
  opacity: 1;
  right: 0;
}

.contactUsHome .blockHome .confirmSubmit {
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  text-align: center;
  border-radius: 1.25em;
  width: 100%;
  height: 100%;
  background-color: #021939;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}
.contactUsHome .blockHome button:hover {
  border: 1px solid #4bace9;
  -webkit-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-duration: 0.5s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.contactUsHome .blockHome .confirmSubmit .message {
  max-width: 80%;
  margin: auto;
  position: relative;
}
.contactUsHome .blockHome .confirmSubmit .close {
  position: absolute;
  border-radius: 3px;
  margin: 0px;
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
}

.close p {
  color: white;
  margin: auto;
}

.contactUsHome .blockHome {
  float: right;
  text-align: left;
  max-width: 567px;
  min-height: 548px;
  margin: 50px 10%;
  position: relative;
  padding: 1px;
  background-color: #021939;
  border-radius: 1.25em;
}

.contactUsHome .blockHome .container {
  max-width: 487px;
  margin: 39px;
}

.contactUsHome h2 {
  margin: 0px;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
}

.contactUsHome p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.87);
}

.contactUsHome input {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 140%;
  color: #4b5566;
  width: 100%;
  height: 54px;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 3px;
  border: 0px solid;
}

.contactUsHome select {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 140%;
  color: #4b5566;
  width: 100%;
  height: 54px;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 3px;
  border: 0px solid;
}

.contactUsHome .errorFirst {
  border: 2px solid red;
  width: 49.5%;
  margin-right: 1%;
}

.contactUsHome .errorLast {
  border: 2px solid red;
  width: 49.5%;
  /* resources */
}

.contactUsHome .error {
  border: 2px solid red;
}

.contactUsHome .errorType {
  border: 2px solid red;
}

.resources_home {
  background-color: #edf1f5;
  background-repeat: no-repeat;
  padding-top: 1px;
  min-width: 100%;
  min-height: 480px;
  background-size: cover;
}

.resources_home .col_home {
  margin: 0;
}

.resources_home .col_home .col_home_header {
  margin-top: 1em;
  text-align: center;
}

.resources_home .col_home .col_home_subheader {
  margin-top: 0;
  text-align: center;
}

.resources_home .col_home .r_button {
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
  width: 250px;
  cursor: pointer;
  border-radius: 30px;
  height: 53px;
  border: 0px;
  background-color: #4bace9;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.04em;
}

/*employed*/
.minos_partners {
  background-color: white;
  background-repeat: no-repeat;
  padding-top: 1px;
  min-width: 100%;
  min-height: 480px;
  text-align: center;
  background-size: cover;
}
.minos_partners .container {
  display: table;
  position: center;
  text-align: center;
  max-width: inherit;
  margin: auto;
  margin-top: 2.5%;
}

.minos_partners .visible {
  animation: move-up 1s ease;
}

.minos_partners h1 {
  display: block;
  padding: 30px;
  font-weight: bold;
  font-size: 52px;
  line-height: 46px;
  color: #1b202b;
}
.minos_partners img {
  display: inline-block;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: auto;
  margin-bottom: 5%;
  max-width: 240px;
}
/* Media Screens -------------------------------*/

@media screen and (max-width: 1800px) {
  .applyNow form {
    width: 99%;
  }
}

.contactUsHome .first {
  width: 49.5%;
  margin-right: 1%;
}

.contactUsHome .last {
  width: 49.5%;
}

.contactUsHome textarea {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 140%;
  color: #4b5566;
  resize: none;
  width: 100%;
  height: 132px;
  padding: 10px;
  border-radius: 3px;
  border: 0px solid;
}

.contactUsHome .blockHome button {
  font-size: 16px;
  line-height: 140%;
  width: 100%;
  color: #fff;
  margin-top: 35px;
  border-radius: 30px;
  height: 52px;
  border: 1px solid #fff;
  background-color: transparent;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.04em;
}

@media screen and (max-width: 1800px) {
  .applyNow form {
    width: 99%;
  }
}

@media screen and (max-width: 1700px) {
  .applyNow form {
    width: 98%;
  }
}

@media screen and (max-width: 1600px) {
  .applyNow form {
    width: 97%;
  }
  .contactUsHome .blockHome {
    float: none;
    margin: 50px auto;
  }

  .contactUsHome .applyNow2 {
    float: none;
    margin: auto;
  }
}

@media screen and (max-width: 1036px) {
  .benefitsCol1 {
    width: 100%;
  }
  .benefitsCol2 {
    width: 100%;
  }

  .benefitsRow .container {
    float: none;
    margin: 20px auto;
  }

  .userContainer {
    display: table;
    margin-top: 60px;
  }

  .userCol {
    display: table-cell;
    float: left;
    width: 100%;
    border: 0;
    padding: 0;
  }

  .userCol .container {
    float: left;
    margin-top: 40px;
    margin-bottom: 0px;
  }

  .userCol .container .student-button {
    margin-top: 5px;
  }

  .applyNow {
    min-width: 100%;
    width: 100%;
    border-radius: 0px;
  }

  .userCol button {
    position: static;
  }
}

@media screen and (max-width: 950px) {
  .contactUsHome .block {
    margin: 50px auto;
  }

  .heroHome .container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 210px;
  }
}

@media screen and (max-width: 988px) {
  .applyNow input {
    width: 41%;
  }

  .applyNow .email {
    width: 41%;
  }

  .applyNow select {
    width: 41%;
  }

  .userContainer {
    margin-top: 113px;
  }

  .contactUsHome {
    display: block;
    padding-bottom: 40px;
  }

  .contactUsLeft {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
  }
  .contactUsRight {
    padding: 10px;
    box-sizing: border-box;
    position: static !important;
    width: 100%;
  }

  .applyNow2 {
    position: static !important;
  }
}
@media screen and (max-width: 840px) {
  .positionLeft-1,
  .positionLeft-2 {
    display: none;
  }
}

@media screen and (max-width: 810px) {
  .heroHome .container h3 {
    font-size: 40px;
  }

  .heroHome .container h2 {
    font-size: 40px;
  }

  .applyNow input {
    width: 40%;
  }

  .applyNow .email {
    width: 80.8%;
  }

  .applyNow select {
    width: 80.8%;
  }

  .testimonials {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
    display: inline-block;
    align-items: center;
    justify-content: center;
  }
  .testimonial {
    margin-bottom: 20px;
    display: inline-block;
  }

  .contactUsHome .block {
    margin: 50px auto;
  }
}

@media screen and (max-width: 806px) {
  .applyNow button {
    text-align: center;
  }
  .contactUs {
    padding: 10px;
  }
}

@media screen and (max-width: 475px) {
  .benefitsRow .image {
    margin-right: 10px;
  }

  .applyNow input {
    width: 80.8%;
  }

  .contactUsHome .errorFirst {
    border: 2px solid red;
    width: 100%;
    margin-right: 0;
  }

  .contactUsHome .errorLast {
    border: 2px solid red;
    width: 100%;
  }

  .contactUsHome .first {
    width: 100%;
    margin-right: 0;
  }

  .contactUsHome .last {
    width: 100%;
  }

  .positions .positionBlock .positionRight {
    padding: 30px;
    padding-bottom: 90px;
  }

  .positionButtonWrapper button {
    margin-bottom: 20px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(5%);
  }
  100% {
  }
}

.hvr-sweep {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #4bace9;
  border-radius: 32px;
  border: 1px solid #4bace9;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep:hover,
.hvr-sweep:focus,
.hvr-sweep:active {
  color: white;
  border: 1px solid #4bace9;
}
.hvr-sweep:hover:before,
.hvr-sweep:focus:before,
.hvr-sweep:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.portrait {
  width: 118px;
  height: 118px;
}
