@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

.container {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.dashboard-component {
    background: #f9f9f9;

    .dashboard-component-title {
        color: #7a7a7a;
        font-weight: 600;
        font-size: 1.5rem;
    }

    .sub-title {
        color: #a3a3a3;
        // margin-left: 20px;
        margin-bottom: 1rem;
    }
    button {
        display: block;    
    }
    
}



