html {
	font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	overflow-x: hidden;
}

.candidate_process {
	background-color: #ffffff;
	background-repeat: no-repeat;
	padding-top: 0px;
	padding-bottom: 0px;
	background-size: cover;
}

.candidate_process .col-container {
	overflow: hidden;
	position: relative;
	display: flex;
	width: 100%;
	min-height: 600px;
	margin-bottom: 0px;
	text-align: left;
}

.candidate_process .right {
	flex: 1 1 0;
	min-width: 0;
	position: relative;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox */
	box-sizing: border-box;

	padding: 50px;
	padding-bottom: 75px;
	margin-bottom: 50px;
}

.edit_manage-acc-container {
    position: relative;
    box-sizing: border-box;
    width: 700px;
margin:auto;
    background-color: #EDF1F5;
    border-radius: 30px;
    min-height: 400px;
    padding:60px;
    padding-bottom: 140px;
    	/* new design*/
	background-color: #ffff;
	box-shadow: rgba(0,0,0,0.1) 0 2px 10px;
}

.candidate_process .col-container .edit_logo_img{
    position: relative;
    margin:0px;
    width: 130px !important;
    height: 130px !important;
    max-height: 130px !important;
    border-radius: 50%;
}

.editAccountTop{
    display:flex;
}
.edit_manage-acc-container .editAccountTop h1{
    
}
.edit_manage-acc-container .editAccountTop p{
    color:black;
}

#my_file{

}
.candidate_process .right .edit_manage-acc-container .heading{
    
 
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 0px;

    /* identical to box height */
    letter-spacing: 0.03em;
    /*text-transform: capitalize;*/
    position: relative;
    color: black;
    
}

.candidate_process .right .edit_manage-acc-container h2{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 0px;
    margin-left: 0px;
    /* identical to box height */
    letter-spacing: 0.03em;
    text-transform: capitalize;
    position: relative;
    float: right;
    margin-right: 118px;
    margin-top: -93px;
    color: black;
    
}

.candidate_process .right .edit_manage-acc-container h3{
    margin:0px;
    margin-top:5px;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    line-height: 1.5;
   width:140px;
    /* identical to box height */
 
    text-transform: capitalize;
    position: relative;
   
}

.candidate_process .right .edit_manage-acc-container input{
    margin:0px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    line-height: 1.5;
    border-radius: 3px;
    color: #4B5566;
    padding: 5px;
    /* identical to box height */
 
    text-transform: capitalize;
    position: relative;
    border: 1px solid #000000;
    
}

.reset_button_edit{
    width: calc(50% - 10px);
    background-color: transparent;
    height: 30px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;

    text-align: center;
    color:  #4BACE9;
}


.reset_button_edit:hover{
    cursor: pointer;
}

.reset_change_button_edit:hover{
    cursor: pointer;
}


.save_button_edit{
    width: 100%;
    height: 28px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}

.candidate_process .wrap_button_edit {
    position:absolute;
    
    bottom: 50px;
    left: 0;
    right: 0;
 
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset_change_button_edit{
    width: calc(50% - 10px);
    
    height: 30px;
    margin-left: 20px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}

.mang-acc-notif{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    color: red;
    display: inline-block;
    font-size: 18px;
}

.mang-acc-notif-hide{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    color: red;
    display: none;
    font-size: 18px;
}
