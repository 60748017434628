.PageNotFoundContainer{
    background-color: white;
    box-sizing: border-box;
    padding-top:80px;
    padding-bottom:80px;
    width: 100%;
    min-height: 700px;
}

.blockPageNotFound{
    background-color: #EDF1F5;
    box-sizing: border-box;
    position: relative;
    padding:40px;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    max-width: 30em;
    width: 100%;
    height:100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
    border-radius: 30px;
}