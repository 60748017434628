
.editManageAccContainer {
    position: relative;
    box-sizing: border-box;

    background-color: #EDF1F5;
    border-radius: 30px;
    min-height: 400px;
    padding:40px;
    padding-bottom: 160px;
    z-index: 1;

    	/* new design*/
	background-color: #ffff;
    flex: 50%;
    max-width:50%;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    margin-left: 32%;
    
}

.editManageAccContainer h3{
    font-size: 20px;
}
.editManageAccContainer input{
    border: 1px solid #DFDFDF;
}


.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
  }
  
  .blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
  }
  
  .blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
  }

.ManageHRContainer{
	background-color: #ffff;
    flex: 50%;
    max-width:50%;
    margin-right: 2.5%;
    padding:40px;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
}

.accountLabelContainer{
    margin-bottom: 20px;
    width:48.75%;
    display:inline-block;
    
}
.accountLabelContainerBusiness{
    margin-bottom: 20px;
    width:48.75%;
    display:inline-block;
    margin-left: 15px;
}

.accountLabelContainerLeft{
    margin-bottom: 20px;
    width:48.75%;
    display:inline-block;
    margin-right: 2.5%;
}

.accountLabelContainerEmail{
    margin-bottom: 20px;
    width:48.75%;
    display:inline-block
}


.candidate_process .col-container .edit_logo_img{
    position: relative;
    margin:0px;
    width: 130px !important;
    height: 130px !important;
    max-height: 130px !important;
    border-radius: 50%;
}

.editAccountTop{
    display:flex;
}
.editManageAccContainer .editAccountTop h1{
    display: block;
}
.editManageAccContainer .editAccountTop p{
    color:black;
}

//#my_file{

//}
.candidate_process .right .editManageAccContainer .heading{
    
 
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 0px;

    /* identical to box height */
    letter-spacing: 0.03em;
    /*text-transform: capitalize;*/
    position: relative;
    color: black;
    
}

.candidate_process .right .editManageAccContainer h2{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 0px;
    margin-left: 0px;
    /* identical to box height */
    letter-spacing: 0.03em;
    text-transform: capitalize;
    position: relative;
    float: right;
    margin-right: 118px;
    margin-top: -93px;
    color: black;
    
}

.candidate_process .right .editManageAccContainer h3{
    margin:0px;
    margin-top:5px;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    line-height: 1.5;
   width:140px;
    /* identical to box height */
 
    text-transform: capitalize;
    position: relative;
   
}

.candidate_process .right .editManageAccContainer input{
    margin:0px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3em;
    line-height: 1.5;
    border-radius: 3px;
    color: #4B5566;
    padding: 5px;
    /* identical to box height */
 
    text-transform: capitalize;
    position: relative;
    border: 1px solid #000000;
    
}

.wrapButton{
    position: absolute;
    width: calc(100% - 80px);
    bottom: 0;
    margin-bottom: 40px;
}

.reset_button_edit{
    width: calc(50% - 10px);
    
    height: 40px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
    
}


.reset_button_edit:hover{
    cursor: pointer;
}

.reset_change_button_edit:hover{
    cursor: pointer;
}


.save_button_edit{
    width: calc(50% - 10px);
    //width: 100%;
    height: 40px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}

.candidate_process .wrap_button_edit {
    position:absolute;
    
    bottom: 50px;
    left: 0;
    right: 0;
 
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset_change_button_edit{
    width: calc(50% - 10px);
    
    height: 40px;
    margin-left: 20px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}

.mangAccNotif{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    color: red;
    display: inline-block;
    font-size: 18px;
}

.mangAccNotifHide{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    color: red;
    display: none;
    font-size: 18px;
}



.createHR{
    border-radius: 30px;
    background-color: #EDF1F5;
    padding: 24px;
    margin-top:20px;
    
}

.createHR h1{
    font-size: 16px;
}

.HRInput{
    margin-bottom:10px;
    color:  #B0B0B0;

}

.createHRButton{
    width: 100%;
    margin-top: 20px;
    height: 40px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}
