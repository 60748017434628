/*
.profile-container{
    position: relative;
    box-sizing: border-box;
    padding:0px;
    width: 100%;
    margin-bottom: 20px;

    background-color: #EDF1F5;
    border-radius: 30px;
    
	background-color: #ffff;
	box-shadow: rgba(0,0,0,0.1) 0 2px 10px;
}

.move-again{
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.top{
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 20px; 
    position: relative;
    min-height: 90px;
    width: 100%;
    cursor: pointer;
}

.profile-pic{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
    height: 70px;

    border-radius: 50%;
}

.profile-title{
    position: absolute;
    left: 125px;
    top: 15%;

    margin: 0px;
    padding: 0px;
    
}


.bottom-show{
    position: relative;
    box-sizing: border-box;
    min-height: 225px;
    width: 100%;
    padding: 10px 20px;
}

.bottom-hide{
    display:none;
}
.bottom-show a{
    text-decoration:none;
}

.bottomButtonWrapper{
    text-decoration:none;
    display:flex;
}

.bottom-button{
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 1px solid #4BACE9;
    text-shadow: 0px 0px 0px transparent;

    color: #4BACE9;
    margin:auto;
    width: 95%;
    height: 40px;
    border-radius: 30px;
    margin:20px auto;
    cursor: pointer;
}


.bottom-top{
    position: relative;
    min-height: 100px;
    
}

.progress-left{
    box-sizing: border-box;
    padding-right:5px;
    display: inline-block;
    width: 50%;
}

.progress-right{

    box-sizing: border-box;
    padding-left:5px;
    display: inline-block;
    width: 50%;
    
}

.progress-bar{
    box-sizing: border-box;
    border-radius: 30px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 10px;
    height: 30px;
    
}

.progress-back{
    box-sizing: border-box;
    border-radius: 30px;
    background-color: rgb(201, 201, 202);
    position: relative;
    height: 30px;
    margin-bottom: 10px;
    
}

.progress-back p{
    position: absolute;
    
    font-size: 16px !important;
    color: white !important;
    margin-top: 3px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0;
    right: 0;
    text-align: center;
}

.progress-back-avg{
    box-sizing: border-box;
    border-radius: 30px;
    background-color: rgb(201, 201, 202);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 400px;
    height: 30px;
    margin-bottom: 10px;
    width: 300px;
}

.progress-back-avg p{
    position: absolute;
    
    font-size: 16px !important;
    color: white !important;
    margin-top: 3px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0;
    right: 0;
    text-align: center;
}

.progress-avg{
    position: absolute;
    left: 325px;
    top: 50%;
    transform: translateY(-50%);
    color: #738094;
}
*/