.borderbox {
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.inner {
  display: flex;
  width: 100%;
  height: 100%;
}
table {
  border-collapse: separate !important;
}

.hrreqToolbar {
  /* Auto Layout */

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 15px 44px;

  // width: 1114px;
  height: 72px;
  left: 0px;
  top: 756px;

  background: #f9f9f9;

  /* Inside Auto Layout */

  flex: none;
  order: 10;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.hrreqClock {
  width: 24px;
  height: 34px;
  left: 0px;
  top: 0px;

  font-family: Font Awesome 5 Free;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #a5a5a5;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 15px;
}

.toolbarInput {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;

  width: 246px;
  height: 34px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border: 1px solid #4bace9;
  border-radius: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 2px;
}

.toolbarInput1 {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;

  width: 180px;
  height: 34px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border: 1px solid #4bace9;
  border-radius: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 2px;
}

.toolbarInput2 {
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  width: 50px;
  height: 34px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border: 3px solid #4bace9;
  border-radius: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 5px;
}

.hrreqTh {
  /* Position */

  width: 243px;
  height: 24px;
  left: 47px;
  top: 19px;

  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  text-align: left;
  text-transform: capitalize;

  color: #4b5566;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.hrreqPosition {
  width: 183px;
  height: 24px;
  left: 47px;
  top: 24px;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-transform: capitalize;

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 59px;
}

.hrreqLocation {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  color: #7a7a7a;
}

.hrreqUserIcon {
  width: 21px;
  height: 34px;
  left: 0px;
  top: 0px;

  font-family: Font Awesome 5 Free;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #a5a5a5;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 15px;
}

.hrreqDuration {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  color: #7a7a7a;
}

.hrreqPAvailable {
  width: 12px;
  height: 24px;
  left: 36px;
  top: 5px;

  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-transform: capitalize;

  color: #7a7a7a;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 70px;
}

.calendar {
  background-color: white;
  flex: 55%;
  margin-right: 2.5%;
  padding: 24px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.calendarHeader {
  // background-color: red;
  margin-bottom: 24px;
}

.calendarHeader h1 {
  color: #4b5566;
  font-size: 20px;
}

.calendarHeader h2 {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
}

.rightContainer {
  flex: 40%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.block {
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.blockInner {
  padding: 24px;
}

.blockHeader {
  // background-color: red;
  margin-bottom: 24px;
}

.blockHeader h1 {
  color: #4b5566;
  font-size: 20px;
}

.blockHeader h2 {
  color: #a5a5a5;
  font-size: 16px;
  line-height: 24px;
}

.buttonmodald {
  color: white !important;
  // padding-left: 15px !important;
  margin-top: 10px !important;
  border-radius: 3px !important;
  height: 40px !important;
  border: 1px solid #ffffff !important;
  background-color: #cc2a2a !important;
  // font-weight: bold !important;
  font-size: 16px !important;
  line-height: 140% !important;
  text-align: center !important;
  // letter-spacing: 0.05em !important;
  border-radius: 30px !important;
  margin: 0.4% !important;
  margin-top: 8px !important;
  margin-bottom: 3px !important;
}

.buttonmodalc {
  color: white !important;
  // padding-left: 15px !important;
  margin-top: 10px !important;
  border-radius: 3px !important;
  height: 40px !important;
  border: 1px solid #ffffff !important;
  background-color: #4bace9 !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 140% !important;
  text-align: center !important;
  // letter-spacing: 0.05em !important;
  border-radius: 30px !important;
  margin: 0.4% !important;
  margin-top: 8px !important;
  margin-bottom: 3px !important;
}
