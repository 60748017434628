html {
	font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

hr {
	visibility: hidden;
	margin: 0px;
}

/* Hero section ----------------- */

.heroPartner {
	background-image: url("../../assets/backgrounds/corporateBackground.png");
	background-repeat: no-repeat;
	background-attachment: fixed;
	padding-top: 1px;
	padding-bottom: 60px;
	min-height: 555px;
	background-size: cover;
	text-align: center;
}

.heroPartner .container {
	text-align: center;
	max-width: 842px;
	margin: auto;
	margin-top: 180px;
}

.heroPartner h1 {
	margin: 10px 20px;
	font-style: normal;
	font-weight: normal;
	font-size: 52px;
	line-height: 130.1%;
	letter-spacing: -0.01em;
	color: #ffffff;
}

.heroPartner p {
	margin: 20px;
	margin-top: 16px;
	margin-bottom: 16px;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 160%;
	/* or 32px */
	text-align: center;
	color: #ffffff;
}

/*benifits of partnering*/
.partnerBenefits {
	background-color: #edf1f5;
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-top: 80px;
	padding-bottom: 80px;
	text-align: left;
}

.partnerBenefits .left {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.partnerBenefits .left .container {
	width: 80%;
}

.partnerBenefits .right {
	height: 100%;
	width: 50%;
}

.partnerBenefits .right .container {
	margin-top: 30px;
	margin-bottom: 50px;
	margin-left: 48px;
	margin-right: 48px;
}

.partnerBenefits h2 {
	font-size: 30px;
	line-height: 45px;
	text-transform: capitalize;

	color: #1b202b;
}

.partnerBenefits p {
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 160%;
	color: #4b5566;
}
.partnerBenefits .verticalCenter p {
	-ms-transform: translateY(50%);
	transform: translateY(50%);
}

.partnerBenefits .right img {
	margin-bottom: auto;
	height: 100%;
	width: 64px;
	float: left;
	margin-right: 10px;
}

/*how it works section*/

.howItWorks .process {
	width: 100%;
	text-align: center;
	margin-bottom: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.howItWorks h2 {
	text-align: center;
	margin-top: 80px;
	margin-bottom: 40px;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
	text-transform: capitalize;

	color: #1b202b;
}

.howItWorks .container {
	margin: 24px;
	margin-top: 0px;
	text-align: center;
	padding: 0px;
	max-width: 320px;
	min-height: 232px;
}

.howItWorks h3 {
	margin: 0px;
	margin-top: 20px;
	margin-bottom: 15px;
	padding: 0px;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	color: #1b202b;
}

.howItWorks p {
	margin: 0px;
	padding: 0px;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: #738094;
}

/*become a partner section*/

.partner {
	background-image: url("../../assets/backgrounds/handshake.png");
	background-repeat: no-repeat;
	padding-top: 1px;
	padding-bottom: 1px;
	min-height: 409px;
	background-size: cover;
	text-align: center;
}

.partner .container {
	margin: 10px;
	margin-top: 120px;
}

.partner h1 {
	margin: 0px;
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
	color: #ffffff;
}

.partner p {
	margin-top: 12px;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 150%;
	text-align: center;

	color: rgba(255, 255, 255, 0.87);
}

.partner button {
	margin-top: 30px;
	color: white;
	width: 170px;

	border-radius: 1px;
	height: 53px;
	border: 0px;
	background-color: #4bace9;
	font-weight: bold;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.04em;
}

/* contact us section ----------------- */

.contactUs {
	background-image: url("../../assets/backgrounds/Rectangle 46.png");
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: 648px;
	background-size: cover;
	padding: 50px;
	text-align: center;
}

.contactUs .block {
	position: relative;
	text-align: left;
	max-width: 567px;
	min-height: 548px;
	margin: 50px;
	margin-left: 120vh;
	padding: 1px;
	background-color: #021939;
	border-radius: 1.25em;
}

.contactUs .block .confirmSubmit {
	box-sizing: border-box;
	position: absolute;
	z-index: 1;
	text-align: center;
	border-radius: 1.25em;
	width: 100%;
	height: 100%;
	background-color: #021939;
	display: flex;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s 0s, visibility 0s 0s;
}

.contactUs .block .confirmSubmit .message {
	max-width: 80%;
	margin: auto;
	position: relative;
}
.contactUs .block .confirmSubmit .close {
	position: absolute;
	border-radius: 3px;
	margin: 0px;
	top: 40px;
	right: 40px;
	width: 40px;
	height: 40px;
}

.close p {
	color: white;
	margin: auto;
}

.contactUs .block .container {
	max-width: 487px;
	margin: 39px;
}

.contactUs h2 {
	margin: 0px;
	color: white;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
}

.contactUs p {
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	color: rgba(255, 255, 255, 0.87);
}

.contactUs input {
	box-sizing: border-box;
	font-size: 16px;
	line-height: 140%;
	color: #4b5566;
	width: 100%;
	height: 54px;
	margin-bottom: 8px;
	padding: 10px;
	border-radius: 3px;
	border: 0px solid;
}

.contactUs select {
	box-sizing: border-box;
	font-size: 16px;
	line-height: 140%;
	color: #4b5566;
	width: 100%;
	height: 54px;
	margin-bottom: 8px;
	padding: 10px;
	border-radius: 3px;
	border: 0px solid;
}

.contactUs .errorFirst {
	border: 2px solid red;
	width: 100%;
	margin-right: 1%;
}

.contactUs .errorLast {
	border: 2px solid red;
	width: 100%;
}

.contactUs .error {
	border: 2px solid red;
}

.contactUs .errorType {
	border: 2px solid red;
}

.contactUs .first {
	width: 100%;
	margin-right: 1%;
}

.contactUs .last {
	width: 100%;
}

.contactUs textarea {
	box-sizing: border-box;
	font-size: 16px;
	line-height: 140%;
	color: #4b5566;
	resize: none;
	width: 100%;
	height: 132px;
	padding: 10px;
	border-radius: 3px;
	border: 0px solid;
}

.contactUs .block button {
	font-size: 16px;
	line-height: 140%;
	width: 100%;
	color: #fff;
	margin-top: 35px;
	border-radius: 30px;
	height: 52px;
	border: 1px solid #fff;
	background-color: transparent;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.04em;
}

.contactUs .block button:hover {
	border: 1px solid #4bace9;
	/*background-color: #4bace9;*/
	-webkit-transition-property: border;
	transition-property: border;
	-webkit-transition-duration: 0.4s; /* Safari */
	transition-duration: 0.4s;
	text-decoration: none;
	overflow: hidden;
	cursor: pointer;
}

@media screen and (max-width: 1021px) {
	.partnerBenefits .p3 p {
		-ms-transform: translateY(0%);
		transform: translateY(0%);
		margin-bottom: -30px;
	}
}
@media screen and (max-width: 970px) {
	.partnerBenefits {
		padding-top: 40px;
	}

	.partnerBenefits .left {
		display: inline-block;
		width: 100%;
	}

	.partnerBenefits .left .container {
		float: none;
		margin: auto;
		max-width: 562px;
	}

	.partnerBenefits .left h2 {
		margin-left: 20px;
		margin-right: 10px;
	}

	.partnerBenefits .left p {
		margin-left: 20px;
		margin-right: 10px;
	}

	.partnerBenefits .right {
		display: inline-block;
		width: 100%;
	}

	.partnerBenefits .right .listContainer {
		float: none;
		margin: auto;
		max-width: 562px;
	}

	.partnerBenefits .right .container {
		margin-right: 20px;
		margin-left: 20px;
	}

	.partnerBenefits .p3 p {
		-ms-transform: translateY(50%);
		transform: translateY(50%);
		margin-bottom: 0px;
	}

	.howItWorks .process {
		width: 100%;
		text-align: center;
		margin-bottom: 50px;
		display: inline-block;
		align-items: center;
		justify-content: center;
	}
	.howItWorks .process .container {
		margin-bottom: 20px;
		display: inline-block;
	}
}

@media screen and (max-width: 950px) {
	.contactUs .block {
		margin: 50px auto;
	}
}

@media screen and (max-width: 806px) {
	.contactUs {
		padding: 10px;
	}
}

@media screen and (max-width: 500px) {
	.heroCorporate .container {
		margin-top: 175px;
	}
}

@media screen and (max-width: 475px) {
	.contactUs .errorFirst {
		border: 2px solid red;
		width: 100%;
		margin-right: 0;
	}

	.contactUs .errorLast {
		border: 2px solid red;
		width: 100%;
	}
	.contactUs .first {
		width: 100%;
		margin-right: 0;
	}

	.contactUs .last {
		width: 100%;
	}
	.partnerBenefits .vertical-center p {
		-ms-transform: translateY(0%);
		transform: translateY(0%);
	}
}

/*
@media screen and (max-width: 461px){
    .partnerBenefits .p3 p{
        -ms-transform: translateY(0%);
        transform: translateY(0%);
        margin-bottom: -30px;
    }
}

@media screen and (max-width: 436px){
    .partnerBenefits .p5 p{
        -ms-transform: translateY(0%);
        transform: translateY(0%);
    }
}
*/

.fade1 {
	display: inline-block;
	animation: fadeIn ease 2s, moveTop 1.2s;
}

.fade2 {
	display: inline-block;
	animation: fadeIn2 ease 2s, moveTop 1.5s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn2 {
	0% {
		opacity: 0;
	}
	15% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes moveTop {
	0% {
		transform: translateY(40px);
	}
	100% {
	}
}
