// @import '~mdb-ui-kit/css/mdb.min.css';
// @import 'fortawesome/fontawesome-free/css/all.min.css'; 
// @import 'bootstrap-css-only/css/bootstrap.min.css'; 
// @import 'mdbreact/dist/css/mdb.css';
.fa-chevron-left{
	display: none !important;
}

.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
  }
  
  .blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
  }
  
  .blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
  }

.inner {
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 1;
  }



.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
  }
  
  .blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
  }
  
  .blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
  }


.block {
	background-color: white;
margin:auto;
	padding: 24px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	max-width: 700px;
}
.formContainer{
	display:flex;
}

.block form{
	margin:auto;
}

.block input{
	height: 40px;
	width:calc(50% - 8px);
	color:#4b5566;
	margin-bottom: 15px;
}
.block select{
	margin-bottom: 10px;
	margin-right: 8px;
	height: 40px;
	width:calc(50% - 8px);
	color:#4b5566;
}

.block .inputField{
	margin-right: 8px;

}

.block .email{

	height: 40px;
	width:calc(100% - 8px);
}
.block button{
	margin-top: 10px;
	border-radius: 22px;
    padding: 8px 15px;
    font-size: 13px;
    font-weight: bold;
    width: 140px;
    max-height: 40px;
    cursor: pointer;
    background-color: #4bace9;
    color: white;
    border: 0px;
}

.resumeTitle{
	color: #4b5566;
    font-size: 20px;
}

.plusButton{
	font-size: 20px;
	margin-top:4px;
	margin-left: 8px;
	color: #4BACE9;
}
.minusButton{
	font-size: 20px;
	margin-top:4px;
	margin-left: 8px;
	color: #4BACE9;
}