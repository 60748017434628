html {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
}

.candidate_process {
  background-color: #ffffff;
  background-repeat: no-repeat;
  padding-top: 0px;
  padding-bottom: 0px;
  background-size: cover;
}

.candidate_process .col-container {
	overflow: hidden;
	position: relative;
	display: flex;
	width: 100%;
	min-height: 600px;
	margin-bottom: 0px;
	text-align: left;
}

.candidate_process .right {
  flex: 1 1 0;
  min-width: 0;
  position: relative;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox */
  box-sizing: border-box;
  display: flex;
  padding: 50px;
  padding-bottom: 75px;
  margin-bottom: 50px;
}

.candidate_filter {
  flex: 1 1 0;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 0px;
  min-height: 650px;
  max-width: 200px;
}

.filterContainer {
  background-color: #edf1f5;
  border-radius: 30px;
  padding: 25px;

  /* new design*/
  background-color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 10px;
}

.candidate_filter h1 {
  text-align: center;
  line-height: 2px;
  margin-bottom: 38px;
  min-height: 20px;
}

.candidates_list {
  display: inline-block;

  right: 50px;

  flex: 1 1 0;
  padding: 0px 40px;
  /*border: solid 1px;*/
}

.course_type {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  margin-right: 6px;
  margin-left: -6px;
  color: #000000;
}

.button {
  position: absolute;
  background: #c4c4c4;
}

.candidate_filter_elements {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 2px;
  margin-right: 6px;
  margin-left: -6px;
}

.dropdown-check-list {
  margin-bottom: 3px;
}

.checkbox_class {
  background: #ffffff;
  border: 1px solid #4bace9;
  box-sizing: border-box;
}

.class-overview-button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 1px solid #4bace9;
  text-shadow: 0px 0px 0px transparent;

  position: absolute;
  bottom: 0px;
  left: 58%;
  transform: translateX(-50%);
  height: 40px;
  width: 70%;
  border-radius: 30px;
  color: #4bace9;
  cursor: pointer;
}
