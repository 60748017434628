

.hireCandidates {
	background-color: white;
	padding: 24px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	z-index: 1;
	width: 100%;
}

.hireCandidatesTop {
	margin: auto;
	margin-bottom: 40px;
	color: #4B5566;
	;
	text-align: center;

	width: 90%;
	height: 100%;
}

.hireCandidatesTop:nth-child():hover {
	cursor: pointer;
}

.hireCandidatesTop h1 {
	font-size: 36px;
}

.hireCandidatesTop p {
	font-size: 18px;
	line-height: 28px;
}

.candidateSelect {
	width: 100%;
	height: auto;
	margin-top: -15px;
}

.hireCandidatesMsg {
	width: 100%;
	height: 150px;
}

.candidateSubmit {
	background-color: #EDF1F5;;
	box-shadow: 0px 0px 0px transparent;
	border: 1px solid #ffffff;
	text-shadow: 0px 0px 0px transparent;
	color: #4B5566;
	font-size: 20px;
	font-weight: bold;
	width: 100%;
	max-width: 300px;
	height: 40px;
	border-radius: 30px;
	padding: 0px;
	cursor: pointer;
}

/* calender*/
.hireCandidatesTop th {
	border: 1px solid #ffffff;
	font-size: 24px;

	width: 20%;
}
.hireCandidatesTop td {
	height: 144px;
	width: 20%;
	border: 1px solid #ffffff;
}
.hireCandidatesTop td h3 {
	margin: 10px;
}
.hireCandidatesTop td h1 {
	margin-top: 10px;
}

.hireCandidatesTop .timeBlock {
	display: none;
}
.hireCandidatesTop .timeBlock h3 {
	margin: auto;
	padding: 13px 10px;
}
.hireCandidatesTop .timeBlock h3:hover {
	background-color: #4bace9 !important;
}
.HiringMessage {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 6; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background: transparent;
	backdrop-filter: blur(5px);
}

/* Modal Content */
.modalContent {
	max-width: 30em;
	min-height: 200px;
	left: calc(50% - 15em);
	top: calc(50vh - 300px);
	animation: fadeIn ease 0.3s;
	background-color: #ffffff;
	color: #4B5566;
	border-radius: 4px;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
}
.modalContent p {
	font-size: 24px;
}
.modalContent button {
	width: 200px;
	height: 49px;
	margin: 10px;
	border-radius: 30px;
	display: inline-block;

	vertical-align: middle;

	text-align: center;
	border: 1px solid #fff;
	background-color: #EDF1F5;
	color: #4B5566;
}
/* The Close Button */
.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.start{
 margin-top: 30px;
 width: 10%;
 margin-right: 50px;
}
.end{
	width: 10%;
	margin-left: 50px;
}
