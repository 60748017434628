.resetContainer {
	background-color: #4bace9;
	box-sizing: border-box;
	padding-top: 80px;
	padding-bottom: 80px;
	width: 100%;
	min-height: 700px;
	text-align: left;
}

.blockReset {
	background-color: #edf1f5;
	box-sizing: border-box;
	position: relative;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	margin: 0 auto;
	max-width: 30em;
	width: 100%;
	height: 100%;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	border-radius: 30px;
}

.confirmReset {
	box-sizing: border-box;
	position: absolute;
	z-index: 1;
	text-align: center;
	border-radius: 30px;
	width: 100%;
	height: 100%;
	display: flex;
	background-color: #edf1f5;

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s 0s, visibility 0s 0s;
}
.resetMessage {
	max-width: 400px;
	margin: auto;
	position: relative;
}

.container {
	margin: 40px;
	box-sizing: border-box;
	height: 100%;
}

.container a {
	color: #4b5566;

	text-decoration: underline;
}

.container h1 {
	font-style: normal;
	font-weight: bold;
	font-size: 28px;

	margin: 0px;
}
.container h3 {
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.container p {
	color: #4b5566;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	margin-bottom: 0px;
}

.container li {
	color: #4b5566;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
}
.resetContainer form input {
	box-sizing: border-box;
	font-size: 16px;
	line-height: 140%;
	color: #4b5566;
	width: 100%;
	height: 45px;
	padding: 10px;
	border-radius: 3px;
	border: 0px solid;
}

 .resetButton {
	cursor: pointer;
	display: inline-block;
	text-align: center;
	width: 100%;
	margin-top: 4.2%;
	margin-bottom: 0%;
	height: 52px;

	font-weight: bold;
	font-size: 18px;
	line-height: 140%;
	/* identical to box height, or 20px */
	letter-spacing: 0.08em;
	text-transform: capitalize;
	background: #edf1f5;
	color: #4bace9;
	border: 1px solid #4bace9;
	box-sizing: border-box;
	border-radius: 1.5em;
}

.message {
	max-height: 0px;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s 0s, visibility 0s 0s, max-height 0.15s ease-out;
}

.resetContainer .message h3 {
	margin-top: 0px;
	font-size: 20px;
}
.message p {
	padding: 10px 35px;
	font-size: 18px;
	margin: 5px 0px;
}

.message .valid {
	color: green;
}

.message .valid:before {
	position: relative;
	left: -35px;
	content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.message .invalid {
	color: red;
}

.message .invalid:before {
	position: relative;
	left: -35px;
	content: "✖";
}
