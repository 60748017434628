/* // Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap */

.firstRow {
    margin: 10px;
    padding: 80px 10px 20px 10px;

    /* padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px; */
}

.container-fluid {
    background-color: #edf1f5;
    padding: 1px;

}

.interviewComponent {
    position: relative;

}

.twilio {

    width: 100%;
    height: 100%;
    background-color: #1B202B;

    position: relative;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

}

/* @media screen and (max-width: 1200px){
    .twilio{
    height: 90%;
    }
}
@media screen and (max-width: 1000px){
    .twilio{
    height: 77%;
    }
}
@media screen and (max-width: 820px){
    .twilio{
    height: 65%;
    }
}
@media screen and (max-width: 750px){
    .twilio{
    height: 100%;
    }
} */

.twilioVideo {
    width: 100%;
    height: 100%;

}

#screenBanner {
    height: 36px;
    max-width: 500px;
    color: #4bace9;
    border-width: 3px;
    border-style: solid;
    border-color: #4bace9;
    line-height: 110%;
    border-radius: 30px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 30px;
    background-color: transparent;
    background-blend-mode: darken;
    display: none
}

.feedbackJumbotron {
    background-color: #4bace9;
}

.feedbackForm {
    background-color: #cccccc;
}

.studentProfileSpinner {
    width: 100%;
    height: 500px;
}

.studentProfile {
    width: 100%;
    height: 500px;

    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.studentProfileInnerComponent {
    padding: 40px !important;
}

.identity {
    background-color: #4bace9;
}

#wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.btn-success {
    color: #fff;
    background-color: #4bace9;
    font-size: 20px;
    border-color: #4bace9;
}

.participantCount {
    margin-top: 50px;
    color: #4bace9;
    padding: 8px;
    border-radius: .25rem;
    border-width: 2px;
    border-style: solid;
    border-color: #4bace9;
}

.participantCount p {
    margin: 5px;
    font-size: 20px;
}

.notes {
    margin-top: 10px;

    width: 100%;
    height: 200px;
    overflow-y: auto;

    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

}

.participants {

    height: 1000%;
    width: 100%;
    position: relative;
}

.placeHolder {
    width: 100%;
    height: 34.8vw;
    background-color: #cccccc;
    display: flex;
    border-radius: 4px 4px 0px 0px;

    justify-content: center;
    align-items: center;

}

.DraftEditor-root {
    height: 120px;
}

.placeHolder p {
    margin: auto;
}

/* CSS to edit video element */
.track1 video {
    width: 100%;
    height: 100%;
    display: block;
    transform: rotateY(180deg);
}

.local {
    position: absolute;
    margin-bottom: 41px;
    right: 0;
    bottom: 0;
    width: 30%;
    border-style: solid;
}

.remote .remoteParticipantVideo video {

    border-radius: 4px 4px 0px 0px;

}


/* screen share styling*/

.remoteShare .remoteParticipantVideo {

    width: 30%;

}

.remoteShare .identity {

    width: 30%;

}

.localShare {
    position: absolute;
    margin-bottom: 42px;
    right: 0;
    bottom: 0;
    width: 30%;
    border-style: solid;
}

.remoteShare .remoteParticipantScreenShare video {

    border-radius: 4px 4px 0px 0px;

}

#tbar {
    height: 90%px;
    width: 100%px;
    position: sticky;
}

.participantZoomed {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.participantHidden {
    display: none;
}

.remoteParticipantVideo {
    width: 100%;
    height: 646px;
}


.interviewComponent .topBar {
    height: 10vw;
    max-height: 160px;
    min-height: 130px;
    width: 100%;
    position: absolute;
    background: linear-gradient(89.44deg, #4BACE9 0%, #4BACE9 0.01%, #31CAD3 100%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px 0px;
    margin-top: -10px;
    z-index: 0;
}

.interviewComponent .topBar .pageName h1 {
    color: white;
    font-size: 36px;
    margin: 25px !important;
    text-align: left;
}




/* Student Profile*/



.profileTitle {
    color: #4b5566;
    font-size: 20px !important;
    text-align: left;
}

.profileContainer {
    background-color: white;
    flex: 100%;
    padding: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-height: 400px;
    z-index: 0;
    border-radius: 4px;
}

.blockHeader {
    background-color: red;
    margin-bottom: 24px;
}

.blockHeader h1 {
    color: #4b5566;
    font-size: 20px;
}

.blockHeader h2 {
    color: #a5a5a5;
    font-size: 16px;
    line-height: 24px;
}

.profileTop {
    margin: 25px 0px;
    display: flex;
    width: 100%;
}


.profileImageContainer {}

.profileImage {
    border-radius: 50%;
    width: 70px;

}

.profileInfo {
    margin-left: 25px;
    text-align: left;
}

.profileInfo h1 {
    font-size: 25px !important;
    color: #1B202B !important;
}

.profileDivider {
    visibility: visible;
    color: #E4E4E4;
    border: 1px solid #E4E4E4
}


/* grades*/


.gradeTitle {
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #475165;

    margin-top: 10px;

}


.profileGrades {
    flex: 20%;
    margin-right: 3%;

}

.profileGrades h2 {
    margin-top: 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}

.grade {
    display: flex;
    margin: 20px 0px;

}

.gradeIconReact {
    width: 45px;

}

.gradeIconJava {
    width: 60px;

}

.gradeIconCyber {
    width: 35px;

}

.gradeInfo {
    text-align: left;
    margin-left: 15px;

}

.c100.barSizeSmall {
    font-size: 80px !important;
}

.greenShareButton {
    background-color: #4CAF50;

    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50%;
}

.redShareButton {
    background-color: #e63434;

    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 3px 2px;
    cursor: pointer;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50%;
}

.greenMuteButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50%;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 6px;

}

.redMuteButton {
    background-color: #e63434;

    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50%;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.greenVideoButton {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 5px;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.redVideoButton {
    background-color: #e63434;

    border: none;
    color: white;
    padding: 5px;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50%;

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.leaveMeeting {
    margin-left: 10px;
    margin-bottom: 3px;
}