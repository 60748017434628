/* position section -------------------------------*/

.positionSection {
	padding: 60px 10px;
	background-color: #053654;
	min-height: 700px;
	text-align: center;
}

.positionHeader {
	max-width: 700px;
	margin: auto;
	margin-bottom: 60px;
}

.positionSection h1 {
	color: white;
	margin: 10px auto;
	font-size: 39px;
}
.positionSection p {
	color: white;
	font-size: 21px;
	margin: 0px;
}

.positions {
	width: 100%;
	text-align: left;

	justify-content: center;
}

.positions h1 {
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
}

.positions .positionBlock {
	position: relative;
	flex: 1 1 0px;
	margin: auto;
	display: flex;
	margin-bottom: 20px;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.12);
	background-color: white;
	border-radius: 30px;
	max-width: 1170px;
}
.positions .positionBlock .positionLeft_1 {
	background-image: url('../../assets/backgrounds/universityBackground.png');

	background-size: 400%;
	background-position: 50% 30%;
	width: 25%;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}
.positions .positionBlock .positionLeft_2 {
	background-image: url('../../assets/backgrounds/corporateBackground.png');

	background-size: 300%;
	background-position: 0% 50%;
	width: 25%;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}

.positions .positionBlock .positionRight {
	flex: 1;
	width: 75%;
	padding: 40px;
	padding-bottom: 100px;
}
.positions .positionBlock h1 {
	margin-top: 0px;
	margin-bottom: 20px;
	color: #4bace9;
	font-size: 26px;
}

.positions .positionBlock p {
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 140%;
	color: black;
}

.positionButtonWrapper {
	width: 100%;
	position: absolute;
	left: 1;
	bottom: 0;
	display: flex;
}
.positionButtonWrapper button {
	color: white;
	width: 158px;
	cursor: pointer;
	height: 53px;
	border: 1px solid #4bace9;
	border-radius: 30px;
	background-color: #4bace9;
	font-weight: bold;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.04em;
	margin-bottom: 30px;
}
