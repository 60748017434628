.candidate_filter {
	flex: 1 1 0;
	box-sizing: border-box;
	display: inline-block;
	margin-left: 0px;
	min-height: 650px;
	max-width: 200px;
}

.filterContainer {
	background-color: #edf1f5;
	border-radius: 30px;
	padding: 25px;

	/* new design*/
	background-color: #ffff;
	box-shadow: rgba(0,0,0,0.1) 0 2px 10px;
}

.candidate_filter h1 {
	text-align: center;
	line-height: 2px;
	margin-bottom: 38px;
	min-height: 20px;
}

.candidates_list {
	display: inline-block;

	right: 50px;

	flex: 1 1 0;
	padding: 0px 40px;
	/*border: solid 1px;*/
}

.course_type {
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	text-transform: capitalize;
	margin-right: 6px;
	margin-left: -6px;

	color: #000000;
}

.button {
	position: absolute;
	background: #c4c4c4;
}

.candidate_filter_elements {
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 24px;
	text-transform: capitalize;
	color: #000000;
	margin-bottom: 2px;
	margin-right: 6px;
	margin-left: -6px;
}

.typeButton:hover {
	cursor: pointer;
}

.dropdownListOpen {
	animation: fade-down ease 0.6s;
}

.dropdownListClose {
	animation: fade-down ease 0.5s;
}

@keyframes fade-down {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.dropdownCheckList {
	margin-bottom: 3px;
}

.checkbox_class {
	background: #ffffff;
	border: 1px solid #4bace9;
	box-sizing: border-box;
}
