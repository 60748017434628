html {
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

hr {
  visibility: hidden;
  margin: 0px;
}

/* Hero section ----------------- */

.heroAbout {
  background: linear-gradient(0deg, rgba(5, 54, 84, 0.5), rgba(5, 54, 84, 0.5)), url('../../assets/veterans/Vetrans_Background.jpg');
  background-position: top;
  background-attachment: fixed;
  box-sizing: border-box;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 615px;
  min-width: 1440px;
  background-size: cover;
  text-align: center;
}

.heroAbout .container {
  text-align: center;
  max-width: 700px;
  margin: auto;
  margin-top: 200px;
}

.heroAbout h1 {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 130.1%;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: #ffffff;
}

.heroAbout p {
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 160%;
  /* or 35px */

  text-align: center;

  color: #ffffff;
}

/*Middle sections*/

.pros {
  display: table;
  width: 100%;
  min-height: 174px;

  text-align: center;
}

.pros .col {
  display: table-cell;
  width: 25%;
}

.pros .col:nth-child(odd) {
  background-color: #f6f6ff;
}

.pros .col:nth-child(even) {
  background-color: #eaedf2;
}

.pros .col .container {
  align-items: center;
  margin: 30px;
  margin-left: 18%;
  margin-right: 18%;
}

.pros .col .container h1 {
  margin: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 38px */
  text-align: center;
  color: #1b202b;
}

.pros .col .container p {
  margin: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  /* or 34px */
  text-align: center;
  color: #738094;
}

/*Our process*/
.process {
  background-color: #ffffff;
  background-repeat: no-repeat;
  padding-top: 1px;
  padding-bottom: 0px;
  background-size: cover;
}

.process .colContainer {
  display: table;
  width: 100%;
  min-height: 650px;
  margin-bottom: 150px;
  text-align: left;
}
.process .left {
  display: table-cell;
  height: 100%;
  width: 45%;
  min-height: inherit;
  float: left;
  margin-right: 5%;
  margin-top: 1%;
}
.process .right {
  display: table-cell;
  height: 100%;
  width: 45%;
  min-height: inherit;
  float: right;
  margin-right: 5%;
  margin-top: 1%;
}

.process .left .container {
  margin-left: 30%;
  text-align: left;
  margin-top: 15%;
  min-height: inherit;
}

.process .left .sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 15%;
}

.process .left h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  margin-top: 0px;
  text-transform: capitalize;
  color: #1b202b;
}

.process .left p {
  margin-top: -30px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 200%;
  /* identical to box height, or 26px */
  color: #738094;
}

.process .right .container {
  margin-right: 30%;
  text-align: left;
  margin-top: 15%;
  min-height: inherit;
}

.process .right .row {
  display: inline-block;
  text-align: left;
}

.process .right .row .image {
  float: left;
  width: 15%;
}

.process .right .row .text {
  margin-left: 5%;
  margin-top: -1%;
  float: left;
  width: 80%;
}

.process .right .line {
  display: inline-block;
  margin-top: 40px;
  margin-left: 0;
  margin-right: 80px;
  width: 66px;
  border-bottom: 2px solid #4bace9;
  transform: rotate(90deg);
  margin-bottom: 52px;
}

.process .right h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  margin-left: 15px;
  /* identical to box height */
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #05a0d8;
}
.process .right p {
  margin-top: -6px;
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  /* or 34px */
  color: #738094;
}
.process .right img {
  margin: auto;
  max-height: 64px;
  max-width: 64px;
}

.process .right text {
  visibility: hidden;
}

/*employed*/
.employed {
  background-color: #eaf0f6;
  background-repeat: no-repeat;
  padding-top: 1px;
  min-width: 100%;
  text-align: center;
  background-size: cover;
  padding-top: 4%;
}
.employed .container {
  display: table;
  position: center;
  text-align: center;
  max-width: inherit;
}

.employed .visible {
  animation: move-up 1s ease;
}

@keyframes move-up {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.employed strong {
  display: block;
  padding: 30px;
  margin: 0;
  margin-bottom: 90px;
  font-size: 38px;
  line-height: 45px;
  color: #1b202b;
}
.employed img {
  display: inline-block;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: auto;
  margin-bottom: 5%;
  max-width: 225px;
}

/* work force */
.workforce {
  background-color: #edf1f5;
  background-repeat: no-repeat;
  padding-top: 1px;
  min-width: 100%;
  min-height: 480px;
  text-align: center;
  background-size: cover;
}

.workforce .col {
  display: table-cell;
  width: 33.3%;
}

.workforce .col .container {
  align-items: center;
  margin: 30px;
  margin-left: 9%;
  margin-right: 9%;
}

.workforce .col .container h1 {
  margin: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #1b202b;
}

.workforce .col .container p {
  margin: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #738094;
}

/*Our curriculum*/
.curriculum {
  background-color: #ffffff;
  background-repeat: no-repeat;
  padding-top: 1px;
  text-align: center;
  background-size: cover;
}
.curriculum .container1 {
  text-align: left;
  max-width: 35%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 3%;
}
.curriculum h1 {
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  text-align: center;
  color: #1b202b;
}
.curriculum p {
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  /* or 25px */

  text-align: justify;

  color: #4b5566;
}
.curriculum .container2 {
  text-align: center;
  max-width: 60%;
  margin: auto;
  padding-bottom: 3%;
}
.curriculum .container2 .l {
  display: inline-block;
  width: 40%;
  padding-bottom: 2%;
  margin-right: 1.5%;
  margin-top: 10px;
  background-color: #edf1f5;
  border: 1px solid #edf1f5;
  box-sizing: border-box;
  border-radius: 30px;
}
.curriculum .container2 .r {
  display: inline-block;
  width: 40%;
  padding-bottom: 2%;
  margin-left: 1.5%;
  margin-top: 10px;
  background-color: #edf1f5;
  border: 1px solid #edf1f5;
  box-sizing: border-box;
  border-radius: 30px;
}

.curriculum h2 {
  margin-top: 5%;
  margin-left: 16%;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  text-align: left;
  color: #1b202b;
}

.courses {
  display: inline-block;
}

.courses .container {
  display: table;
  max-width: 310px;
}

.courses .block {
  display: inline-block;
  background-color: white;
  width: 70%;
  min-height: 46px;
  border-radius: 30px;
  margin: 1%;
}

.courses .block text {
  float: left;
  padding: 4%;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  color: #4b5566;
}

.request-button .container {
  display: inline-block;
  min-height: 52px;
  text-align: center;
  margin-top: 1.7%;
  margin-bottom: 4%;
  width: 100%;
}

.request-button .container button {
  float: center;
  cursor: pointer;
  min-width: 50%;
  height: 52px;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background: transparent;
  color: #4bace9;
  border: 1px solid #4bace9;
  box-sizing: border-box;
  border-radius: 30px;
}

.request-button button:hover {
  /* identical to box height, or 20px */
  background: #4bace9;
  color: #ffffff;
  border: 1px solid #4bace9;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

/*become a student section*/

.apply {
  background-image: url('../../assets/backgrounds/Rectangle 25.png');
  background-repeat: no-repeat;
  padding-top: 1px;
  padding-bottom: 1px;
  min-height: 409px;
  background-size: cover;
  text-align: center;
}

.apply .container {
  margin: 10px;
  margin-top: 120px;
}

.apply h1 {
  margin: 0px;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 46px;
  color: #ffffff;
}

.apply p {
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  text-align: center;

  color: rgba(255, 255, 255, 0.87);
}

.apply button {
  margin-top: 30px;
  color: white;
  width: 170px;
  cursor: pointer;
  border-radius: 1px;
  height: 53px;
  border: 1px solid #4bace9;
  border-radius: 30px;
  background-color: #4bace9;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.04em;
}

@media screen and (max-width: 1552px) {
  .curriculum .container1 {
    max-width: 45%;
  }
  .curriculum .container2 .l {
    width: 45%;
  }

  .curriculum .container2 .r {
    width: 45%;
  }

  .request-button .container button {
    min-width: 56%;
  }
}

@media screen and (max-width: 1400px) {
  .curriculum .container1 {
    display: inline-block;
    max-width: 50%;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .curriculum .container2 {
    display: table;
  }

  .curriculum .container2 .l {
    margin: 0px;
    width: 75%;
    margin-bottom: 4%;
  }

  .curriculum .container2 .r {
    margin: 0px;
    width: 75%;
    margin-top: 4%;
    margin-bottom: 4%;
  }

  .request-button .container {
    margin: 0px;
    margin-top: 2.4%;
    margin-bottom: 4.8%;
  }
  .request-button .container button {
    min-width: 45%;
  }
}

@media screen and (max-width: 1280px) {
  .process .left .container {
    min-height: 1090px;
  }
}

@media screen and (max-width: 1134px) {
  .curriculum .container1 {
    display: inline-block;
    max-width: 70%;
    margin-top: 4%;
  }

  .curriculum .container2 {
    display: table;
    padding-bottom: 6%;
  }

  .curriculum .container2 .l {
    margin: 0px;
    width: 100%;
    margin-top: 4%;
    margin-bottom: 6%;
  }

  .curriculum .container2 .r {
    margin: 0px;
    width: 100%;
    margin-top: 6%;
    margin-bottom: 4%;
  }
}

@media screen and (max-width: 1100px) {
  .process .colContainer {
    min-height: 1120px;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .process .left .container {
    margin-left: 15%;
    margin-right: 5%;
    min-height: 1090px;
  }

  .process .right .container {
    margin-left: 5%;
    margin-right: 15%;
  }

  .pros .col {
    display: inline-block;
    width: 100%;
  }

  .pros .col .container {
    float: middle;
    margin-top: 40px;
  }

  .employed .container {
    min-width: 80%;
  }

  .employed h1 {
    font-size: 38px;
    line-height: 46px;
  }
  .employed img {
    margin-left: 80px;
    margin-right: 80px;
    max-width: 210px;
  }
}

@media screen and (max-width: 1080px) {
  .process .colContainer {
    min-height: 300px;
  }

  .process .left {
    display: block;
    margin-left: 15%;
    margin-right: 15%;
    width: 65%;
  }

  .process .left .container {
    text-align: center;
    margin-top: 10%;
    min-height: 5%;
  }

  .process .left .sticky {
    position: center;
    top: 0px;
  }

  .process .left p {
    line-height: 150%;
  }

  .process .right {
    display: inline-block;
    margin-right: 0%;
    margin-left: 0%;
    width: 88%;
  }

  .process .right .container {
    margin-top: 0px;
  }

  .process .right .line {
    display: inline-block;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 80px;
    width: 66px;
    border-bottom: 2px solid #4bace9;
    transform: rotate(90deg);
    margin-top: 100px;
  }

  .process .right img {
    margin: auto;
    max-height: 64px;
    max-width: 64px;
  }
}

@media screen and (max-width: 800px) {
  .employed img {
    margin-left: 40px;
    margin-right: 40px;
    max-width: 180px;
  }

  .curriculum .container1 {
    display: inline-block;
    max-width: 70%;
    margin-top: 4%;
  }

  .curriculum .container2 {
    max-width: 100%;
    display: table;
    padding-bottom: 10%;
  }

  .curriculum .container2 .l {
    margin: 0px;
    width: 80%;
    margin-top: 4%;
    margin-bottom: 6%;
  }

  .curriculum .container2 .r {
    margin: 0px;
    width: 80%;
    margin-top: 6%;
    margin-bottom: 4%;
  }
}

@media screen and (max-width: 590px) {
  .process .left {
    display: block;
    margin-left: 10%;
    margin-right: 10%;
    width: 75%;
  }
  .process .right {
    width: 100%;
  }
  .process .right .container {
    margin-left: 8%;
    margin-right: 8%;
  }
  .heroAbout .container {
    max-width: 80%;
  }

  .courses .block {
    width: 90%;
  }
}

.fade1 {
  display: inline-block;
  animation: fadeIn ease 2s, moveTop 1.2s;
}

.fade2 {
  display: inline-block;
  animation: fadeIn2 ease 2s, moveTop 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn2 {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveTop {
  0% {
    transform: translateY(40px);
  }
  100% {
  }
}
