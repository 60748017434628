.hrContainer {
	position: relative;
	box-sizing: border-box;
    display: inline-block;
    
	width: 100%;
	min-width: 320px;
	height: 60px;
	border: solid 1px;
	border-color: #4BACE9;
	border-radius: 4px;
	margin: 10px 0px;
	padding: 0px 30px;
}

.hrContainer:hover {
    cursor: pointer;
    background-color:#4BACE9 ;
}

.hrProfilePic {
	position: absolute;
	left: 30px;
	top: 50%;
	transform: translateY(-50%);
	height: 50px;
	width: 50px;
	border-radius: 50%;
}

.hrCheckbox {
	position: absolute;
	right: 30px;
	top: 50%;
	transform: translateY(-50%);
	background-color: none;

	height: 18px;
	width: 18px;
}

.hrInfo {
	position: absolute;
	left: 90px;
	top: 50%;
	transform: translateY(-50%);
	margin: 0px;
	text-align: left;
	line-height: 20px !important;
}

.hrName {
	font-size: 18px;
	font-weight: bold;
}

.hrTitle {
	font-size: 14px;
}
