html {
	font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
	overflow-x: hidden;
}

.candidate_process {
	background-color: #ffffff;
	background-repeat: no-repeat;
	padding-top: 0px;
	padding-bottom: 0px;
	background-size: cover;
}

.candidate_process .col-container {
	overflow: hidden;
	position: relative;
	display: flex;
	width: 100%;
	min-height: 600px;
	margin-bottom: 0px;
    text-align: left;
    background-color: #EDF1F5;
}

.candidate_process .right {
	flex: 1 1 0;
	min-width: 0;
	position: relative;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox */
	box-sizing: border-box;

	padding: 50px;
	padding-bottom: 75px;
	margin-bottom: 50px;
}

.manage-acc-container {
    position: relative;
    box-sizing: border-box;
    width: 700px;
margin:auto;
    background-color: #EDF1F5;
    border-radius: 30px;
    min-height: 400px;
    padding:60px;
    padding-bottom: 140px;
/* new design*/
background-color: #ffff;
box-shadow: rgba(0,0,0,0.1) 0 2px 10px;
    
}

.manageImgContainer{
    width:100%;
    display:flex;
    
}

.candidate_process .col-container .logo_img{
margin:auto;
    width: 130px !important;
    height: 130px !important;
    max-height: 130px !important;
    border-radius: 50%;
}

.accountContainer{
    width: 100%;
    
}

.accountLabelContainer{
    width:100%;
    display:flex;
    margin-bottom: 40px;
    
    
}
.candidate_process .right .manage-acc-container h1{
    
    font-weight: bold;
    text-align: center;
    margin-bottom:40px;
    
}

.candidate_process .right .manage-acc-container h4{
    
    margin:0px;
    margin-top:5px;
    width:140px;
    font-size: 1.3em;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    
}

.candidate_process .right .manage-acc-container p{
    
    font-size: 1.3em;
    font-weight: normal;
    color:black;
    margin:0px;
    line-height: 1.5;
    width:100%;

    
    padding: 5px;

    border-bottom: 1px solid rgb(0, 0,0.25);
    
    
}


.candidate_process .wrap_button_acc {
    position: absolute;
    
    bottom: 40px;
    box-sizing: border-box;
    width: calc(100% - 120px); /* Must change if container padding is changed*/
    margin:auto;
    display: flex;

}

.candidate_process .wrap_button_acc0{
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 120px); /* Must change if container padding is changed*/
    
    bottom: 90px;
 

}

.help_button_acc{ /*help*/
    width: calc(50% - 10px);
    
    height: 30px;
    margin-left: 20px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}


.resetPass_button_acc{ /*reset*/
    width: calc(50% - 10px);
    
    height: 30px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}

.resetPass_button_acc:hover{
    cursor: pointer;
}

.wrap_button_acc a{
    width:100%;
}

.edit_button_acc{
     width: 100%;
    height: 28px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    background-color: transparent;
    text-align: center;
    color:  #4BACE9;
}

.edit_button_acc:hover{
    cursor: pointer;
}

.mang-acc-help{
    border: 1px solid black;
    border-radius: 1.25em;
    color: white;
    text-align: center;
    position: absolute;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    top: 25%;
    background-color: #021939;

    width: 500px;
    padding: 20px;
    height: 335px;
}

.mang-acc-help-hide{
    display: none;
}

.mang-acc-help-send{
    width: 300px;
    height: 30px;
    border: 1px solid  #4BACE9;
    border-radius: 30px;
    display: inline-block;
    background-color: transparent;
    
    vertical-align: middle;

    text-align: center;
    color:  #4BACE9;
    cursor: pointer;
    margin-top: 20px;
}

.close-help{
    display: inline-block;
    border: 1px solid white;
    border-radius: 30%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 450px;
}

.close-help:hover{
    display: inline-block;
    border: 1px solid #4BACE9;
    border-radius: 30%;
    color: #4BACE9;
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 450px;
}

@media screen and (max-width: 950px) {
   
    .accountLabelContainer{
        display:block;       
    }

    .candidate_process .right .manage-acc-container h4{
    
        margin:0px;
        width:100%;
        
    }
    
    .candidate_process .right .manage-acc-container p{
        

        margin:0px;
        width:100%;
        
    }

}
