html {
	font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

footer {
	padding: 30px 20px;
	padding-bottom: 20px;
	background-color: #1b202b;
	text-align: center;
	color: white;
}

footer .logo {
	margin-bottom: 15px;
}

footer .logo:hover {
	cursor: pointer;
}

footer .icons img {
	margin: 0px 15px;
}

footer .icons img:hover {
	color: #4bace9;
}

.icons {
	margin-top: 15px;
}

.minos_partners img {
	display: inline-block;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: auto;
	max-width: 240px;
	height: 50px;
}

footer .sections a {
	display: inline-block;
	margin: 10px 10px;
	color: #ffffff;
	text-align: center;
}

footer h2{
	margin-bottom: 14px;
}

footer a:hover {
	color: #4bace9;
}

footer hr {
	margin-top: 40px;
	visibility: visible;
	width: 100%;
	border: 1px solid rgba(117, 126, 137, 0.5);
}

.contact a {
	color: #ffffff;
	text-decoration: none;
}

.contact address {
	margin-top: 30px;
	text-align: left;
}

.foot {
	padding-top: 10px;
	width: 100%;
	display: table;
}

.foot a {
	text-decoration: none;
}

.foot p:hover {
	color: #4bace9;
}
.foot p {
	font-size: 14px;
	line-height: 150%;
	color: #ffffff;
	opacity: 0.5;
}

.foot div {
	display: table-cell;
	width: 33%;
	margin-top: 35px;
}
footer .left {
	text-align: left;
}
footer .right {
	text-align: right;
}

footer .center {
	text-align: center;
}

/* logout -------------------------*/

.privacySection {
	display: none;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: transparent;
	backdrop-filter: blur(5px);
	color: #f2f2f2;
	position: fixed;
	z-index: 6;
	animation: fadeIn ease 0.3s;
}

.privacyBox {
	display: none;
	-ms-flex-direction: column;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	left: calc(50% - 15em);
	top: calc(50vh - 300px);
	min-width: 30em;
	min-height: 300px;

	background-color: #021939;
	color: #f2f2f2;
	border-radius: 30px;
	z-index: 6;
	position: fixed;
	animation: fadeIn ease 0.3s;
}

.privacyButtons {
	margin-top: 0px;
	display: flex;
}

.privacyButtons button {
	width: 200px;
	height: 49px;
	margin: 10px;
	border-radius: 30px;
	display: inline-block;

	vertical-align: middle;

	text-align: center;
	border: 1px solid #fff;
	background-color: transparent;
	color: #fff;
}

@media screen and (max-width: 960px) {
	.sections, .contact, .minos_partners_container {
		padding-bottom: 50px;
	}
}

@media screen and (max-width: 675px) {
	.foot div {
		text-align: center;
		display: block;
		width: 100%;
	}
}

@media screen and (max-width: 550px) {
	footer .sections {
		width: 100%;
		text-align: center;
	}

	footer .sections a {
		margin: 0px;
		width: 50%;
	}
}


