.articleSlide{
    padding:40px;
}


.articleSlide .slideBlock{
    margin:auto;
    max-width: 700px;
    border-radius: 30px;
    padding:40px;
    position:relative;
    background-color: #EDF1F5;
}

.articleSlide .slideBlock h1{
    text-align: center;
}

.articleSlide .slideShowButton{
    display:flex;
    justify-content: center;
}
.slideImgContainer{
    text-align: center;
}
.slideImgContainer img{
    max-height:262px;
}

.articleSlide .slideShowButton button{
    color: white;
    width:158px;
    cursor: pointer;
    height: 53px;
    border: 1px solid #4BACE9;
    border-radius: 30px;
    background-color: #4BACE9;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.04em;
}

/* Next & previous buttons */
.prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: #4BACE9;
    font-weight: bold;
    font-size: 36px;
    transition: 0.6s ease;
    user-select: none;
  }
  
  /* Position the "next button" to the right */
  .prev {
    left: 0;
    border-radius: 0 3px 3px 0;
  }
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    background-color: rgb(2, 25, 57,0.8);
  }