html {
	font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button .student_Login{
  background: none;
  border: none;
}

header {
	width: 100%;
	// background-color: transparent;
	background-color: #021939;
	position: fixed;
	z-index: 4;
	top: 0px;
  transition: background-color .5s ease;
}

.topnav {
	padding-top: 0px;
	padding-right: 33px;
	padding-left: 42.86px;
	overflow: hidden;
	// background-color: transparent;
  background-color: #021939;
  transition: background-color .5s ease;
}

.topnav .active {
	padding: 18px 20px 14px !important;
}

.topnav a {
	margin: 16.5px 10px 0px;
	text-align: center;
	float: right;
	display: block;
	color: #f2f2f2;
	text-align: center;
	padding: 10px;
	text-decoration: none;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 130%;
}

.topnav .icon {
	padding: 10px !important;
	display: none;
}

.hide_loginNav {
	display: none;
}

.dropdown {
	float: right;
	overflow: hidden;
	margin-top: 16.5px;
}

.dropdown .dropbtn {
	border-radius: 2px 2px 0px 0px;
	font-size: 15px;
	font-weight: bold;
	line-height: 130%;
	border: none;
	outline: none;
	color: #f2f2f2;
	padding: 10px 20px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
}

.dropdownContent {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 114px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 0px 2px 2px 2px;
}


.dropdownContent button {
	border-radius: 2px 2px 0px 0px;
	min-width: 114px;
	font-size: 15px;
	font-weight: bold;
	line-height: 130%;
	border: none;
	outline: none;
	color: black;
	padding: 12px 16px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
	text-align: left;
}

.dropdownContent button:hover {
	background-color: #ddd;  
}

.dropdownContent a {
	float: none;
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

.dropdown:hover .dropbtn {
	color: #4bace9;
//	background-color: white;
}

.dropdownContent a:hover {
	background-color: #ddd;
	color: black;
}

.dropdown:hover .dropdownContent {
	display: flex;
  flex-direction: column;
}

.dropdownLogin {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 98px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 0px 2px 2px 2px;
}

.dropdownLogin a {
	float: none;
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

.dropdownLogin a:hover {
	background-color: #ddd;
	color: black;
}

.dropdown:hover .dropdownLogin {
	display: block;
}

.student_LoginTab {
	float: right;
	margin-top: 1px;
	overflow: hidden;
}

.student_Login:hover {
	color: #4bace9;
//	background-color: white;
}

.topnav .applyButton {
	border-radius: 30px;
	padding: 10px 20px;
	background-color: #4bace9;
}

.topnav .icon div {
	width: 30px;
	height: 1.5px;
	background-color: #fff;
	margin: 8px 0;
	transition: 0.4s;
}

@media screen and (max-width: 950px) {
	.topnav {
		padding-left: 38px;
	}
}
@media screen and (max-width: 800px) {
	.topnav {
		padding: 0px;
	}

	.dropdown .dropbtn {
		padding: 20px;
	}
	.dropdown {
		margin: 0px;
	}

	.topnav a:not(:first-child),
	.dropdown .dropbtn {
		display: none;
	}

	.topnav .student_Login{
		display:flex;
    flex-direction: column;
	}

	.topnav a.icon {
		padding: 20px;
		margin: 0px;
		display: block;
		position: absolute;
		right: 15px;
		top: 7px;
	}
}

@media screen and (max-width: 800px) {
	.topnav.responsive {
		padding: 0px;
		position: relative;
		background-color: #021939;
	}

	.topnav.responsive a {
		padding: 20px;
		margin: 0px;
		float: none;
		display: block;
		text-align: left;
	}

	.topnav.responsive .applyButton {
		border-radius: 0px;
		background-color: #021939;
		border: 0px;
		padding: 20px !important;
	}

	.topnav.responsive .applyButton:hover {
		color: #4bace9;
		background-color: white;
	}

	.topnav.responsive .student_Login {
		border-radius: 0px;
		background-color: #021939;
		border: 0px;
		padding: 20px !important;
	}

	.topnav.responsive .student_Login:hover {
		color: #4bace9;
		background-color: white;
	}

	.topnav.responsive .active {
		float: none !important;
		z-index: 12;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
	}

	.topnav.responsive .bar1 {
		-webkit-transform: rotate(-45deg) translate(-9px, 4px);
		transform: rotate(-45deg) translate(-9px, 4px);
	}

	.topnav.responsive .bar2 {
		opacity: 0;
	}

	.topnav.responsive .bar3 {
		-webkit-transform: rotate(45deg) translate(-9.5px, -5px);
		transform: rotate(45deg) translate(-9.5px, -5px);
	}

	.topnav.responsive .dropdown {
		float: none;
	}

	.topnav.responsive .dropdownContent {
		border-radius: 0px;
		position: relative;
	}
	.topnav.responsive .dropdownContent button {
		border-radius: 0px;
		position: relative;
		padding: 20px;
		width: 100%;
	}
	.topnav.responsive .dropdown .dropbtn {
		border-radius: 0px;
		display: block;
		width: 100%;
		text-align: left;
	}
}

/*------------------------------------------------------Corporate Login------------------------------------------------------*/

.login{
  display: none;
  align-items: center;
  justify-content: center;
  width:100%;
  height: 100%;
  background: transparent;
  backdrop-filter: blur(5px);
  color: #f2f2f2;
  position:fixed;
  z-index: 5;
  animation: fadeIn ease .3s;
}

.loginbox{
  display: none;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: calc(50% - 15em);
  top: calc(50vh - 300px);
  min-width: 30em;
  min-height: 600px;
  background-color: #021939;
  color: #f2f2f2;
  border-radius: 30px;
  z-index:6;
  position: fixed;
  animation: fadeIn ease .3s;
}

.loginbox h1{
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  margin: 6px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.loginbox h3{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.loginbox form{
  width: 80%;
}

.loginbox input{
  box-sizing: border-box;
  font-size: 16px;
  line-height: 140%;
  color: #4B5566;
  width: 100%;
  height: 52px;
  padding: 10px;
  border-radius: 3px;
  border: 0px solid;
}
.loginbox .checkContainer{
  margin-top: 16px;
  display:flex;
}
.loginbox .checkContainer p{
  margin:0px;
  margin:auto 10px;
}
.loginbox .check{
  box-sizing: border-box;
  font-size: 16px;
  line-height: 140%;
  color: #4B5566;
  float:left;
  height: 20px;
  width:20px;
  border-radius: 3px;
  border: 0px solid;
}

.invalidMessage{
  text-align: center;
  margin-top: 20px;
  margin-bottom: -20px;
  display:none;
}

.loginbox button{
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  height: 52px;
  
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: 0.08em;
  text-transform: capitalize;
  background:  #EDF1F5;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 30px;
  -webkit-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-duration: 0.5s;
}

.loginbox .loginlink{
  width: 80%;
}

.loginbox .loginlink button{
  display: inline-block;
  text-decoration: underline;
  text-transform: none;
  width: 50%;
  margin-top: 5px;
  color: #FFFFFF;
  background: transparent;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;  
}

.loginbox .loginlink button:hover{
  color: #4BACE9;
  cursor: pointer;
}

.forgotpass{
  display: none;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: calc(50% - 15em);
  top: calc(50vh - 300px);
  min-width: 30em;
  min-height: 600px;
  background-color: #021939;
  color: #f2f2f2;
  border-radius: 30px;
  z-index:7;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  animation: fadeIn ease .3s;
}

.forgotpass h1{
  margin-bottom:20px;
}

.forgotpassConfirm{
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: calc(50% - 15em);
  top: calc(50vh - 300px);
  min-width: 30em;
  min-height: 600px;
  background-color: #021939;
  color: #f2f2f2;
  border-radius: 30px;
  z-index:7;
  position: fixed;
  display: none;
  opacity: 0;
  visibility: hidden;
  animation: fadeIn ease .3s;
}
.forgotpassConfirm .container{
  
  margin:auto;
  max-width: 400px;
}

.createAcct{
  display: none;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: calc(50% - 15em);
  top: calc(50vh - 300px);
  max-width: 30em;
  min-height: 600px;
  background-color: #021939;
  color: #f2f2f2;
  border-radius: 30px;
  z-index:8;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  animation: fadeIn ease .3s;
}

.createAcct h1{
  margin-top: 6px;
}

.createAcct form input{
  margin-top: 12px;
  margin-bottom: 12px; 
  margin-left: 0px;
  margin-right: 0px;
}

.createAcct form button{
  width: 100%;
  margin-top: 24px;
  
}

.createAcctConfirm{
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: calc(50% - 15em);
  top: calc(50vh - 300px);
  min-width: 30em;
  min-height: 600px;
  background-color: #021939;
  color: #f2f2f2;
  border-radius: 30px;
  z-index:7;
  position: fixed;
  display: none;
  opacity: 0;
  visibility: hidden;
  animation: fadeIn ease .3s;
}
.createAcctConfirm .container{
  
  margin:auto;
  max-width: 400px;
}

.createAcctConfirm button{
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  height: 52px;
  
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: 0.08em;
  text-transform: capitalize;
  background:  #EDF1F5;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 30px;
  -webkit-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-duration: 0.5s;
}

/*------------------------------------------------------Student Login------------------------------------------------------*/

.studentLogin{
  display: none;
  align-items: center;
  justify-content: center;
  width:100%;
  height: 100%;
  background: transparent;
  backdrop-filter: blur(5px);
  color: #f2f2f2;
  position:fixed;
  z-index: 5;
  animation: fadeIn ease .3s;
}

.studentLoginbox{
  display: none;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: calc(50% - 15em);
  top: calc(50vh - 300px);
  min-width: 30em;
  min-height: 600px;
  background-color: #021939;
  color: #f2f2f2;
  border-radius: 30px;
  z-index:6;
  position: fixed;
  animation: fadeIn ease .3s;
}

.studentLoginbox h1{
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  text-align: center;
  margin: 6px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.studentLoginbox h3{
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.studentLoginbox form{
  width: 80%;
}

.studentLoginbox input{
  box-sizing: border-box;
  font-size: 16px;
  line-height: 140%;
  color: #4B5566;
  width: 100%;
  height: 52px;
  padding: 10px;
  border-radius: 3px;
  border: 0px solid;
}
.studentLoginbox .checkContainer{
  margin-top: 16px;
  display:flex;
}
.studentLoginbox .checkContainer p{
  margin:0px;
  margin:auto 10px;
}
.studentLoginbox .check{
  box-sizing: border-box;
  font-size: 16px;
  line-height: 140%;
  color: #4B5566;
  float:left;
  height: 20px;
  width:20px;
  border-radius: 3px;
  border: 0px solid;
}

.invalidMessage{
  text-align: center;
  margin-top: 20px;
  margin-bottom: -20px;
  display:none;
}

.studentLoginbox button{
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  height: 52px;
  
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: 0.08em;
  text-transform: capitalize;
  background:  #EDF1F5;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 30px;
  -webkit-transition-property: border;
  transition-property: border;
  -webkit-transition-duration: 0.5s; /* Safari */
  transition-duration: 0.5s;
}

@keyframes fadeIn{
  0% {opacity: 0;}
  100% {opacity: 1;}
}
