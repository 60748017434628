.borderbox {
	box-sizing: border-box;
}

.container {
	width: 100%;
	height: 100%;
	z-index: 1;
}

.inner {
	display: flex;
	width: 100%;
	height: 100%;
}

.calendar {
	background-color: white;
	flex: 55%;
	margin-right: 2.5%;
	padding: 24px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
}

.calendarHeader {
	// background-color: red;
	margin-bottom: 24px;
}

.calendarHeader h1 {
	color: #4b5566;
	font-size: 20px;
}

.calendarHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
}

.rightContainer {
	flex: 40%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.block {
	background-color: white;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: column;
	margin: 10px;
	border-radius: 3px;
}

.blockInner {
	padding: 24px;
}

.blockHeader {
	// background-color: red;
	margin-bottom: 24px;
}

.blockHeader h1 {
	color: #4b5566;
	font-size: 20px;
}

.blockHeader h2 {
	color: #a5a5a5;
	font-size: 16px;
	line-height: 24px;
}
