html {
	font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.articleContainer {
	padding: 50px;
	background-color: #edf1f5;
	min-height: 500px;
}

.articleBlock {
	max-width: 867px;
	min-height: 500px;
	border-radius: 30px;
	padding: 60px;
	padding-bottom: 120px;
	padding-top: 40px;
	position: relative;
	background-color: white;
	margin: auto;
}

.articleBlock .imgWrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}
.articleBlock .imgWrapper .articleImg {
	margin: auto;
}

.articleBlock h1 {
	font-size: 36px;
	margin: 0px;
	margin-bottom: 5px;
}

.articleBlock p {
	font-size: 21px;
	line-height: 32px;
}
.articleBlock .resourceHead {
	display: flex;
	justify-content: space-between;
}

.articleBlock .resourceTags {
	margin: 5px 0px;
}

.articleBlock .share {
	margin-left: -5px;
}

.articleBlock .share h6 {
	font-size: 16px;
	margin: 0px;
	margin-bottom: 5px;
	margin-left: 5px;
}

.articleBlock .share {
	position: absolute;
	bottom: 0;
	margin-bottom: 40px;
}

.articleBlock .share_button {
	margin-right: 0.5em;
}
