.inner {
	display: flex;
	width: 100%;
	height: 100%;
	z-index: 1;
  }

.partners_list {
	background-color: white;
	flex: 75%;
	margin-right: 2.5%;
	padding: 24px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}
