.profile-container {
	position: relative;
	box-sizing: border-box;
	padding: 0px;
	width: 100%;
	margin-bottom: 20px;

	background-color: #edf1f5;
	border-radius: 30px;
	

	/* new design*/
	background-color: #ffff;
	//box-shadow: rgba(0,0,0,0.1) 0 2px 10px;
}

.profile-container:hover {
	cursor: pointer;
}

.move-again {
	position: absolute;
	right: 80px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.favorite {
	font-size: 25px;
}

.gray {
	color: #cccccc;
	;
}

.gold {
	color: #f6d832;
}

.favorite:hover {
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #4bace9;
}

.calendarIcon{
	position: absolute;
    right: 130px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
	font-size: 25px;
	color: #cccccc;
}

.openProfileIcon{
	position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
	font-size: 25px;
	color: #cccccc;
}

.caretIcon{
	position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    cursor: pointer;
	font-size: 25px;
	color: #cccccc;
	transition: transform .5s;
}
.caretIconDown{
	position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%) ;
    cursor: pointer;
	font-size: 25px;
	color: #cccccc;
	transition: transform .5s;
}

.candidate_process .right .top {
	display: inline-block;
	box-sizing: border-box;
	padding: 10px 20px;
	position: relative;
	min-height: 90px;
	width: 100%;
	cursor: pointer;

	padding-right: 80px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.candidate_process .right .profile-pic {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 70px;
	height: 70px;

	border-radius: 50%;
}

.profile-title {
	position: absolute;
	left: 115px;

	margin: 0px;
	padding: 0px;
}
.candidate_process .right .profile-title h2{
	padding-top: 0px;
	margin-top: 10px;
	margin-left: 0px;
	margin-bottom: 5px;
	font-size: 24px;
}
.candidate_process .right .profile-title p{
	margin-top: 0px;
	margin-left: 0px;
	font-size: 16px;
}



.candidate_process .right .candidateDivider{
	border-top: 1px solid #CACACA;
	height: 0px;
	visibility: visible;
}


.candidate_process .right .bottom-show {
	position: relative;
	box-sizing: border-box;
	
	width: 100%;
	padding: 10px 20px;
	padding-top:0px;
	background-color: #edf1f5;
	border-radius: 0px 0px 30px 30px;

	/* new design*/
	background-color: #ffff;
    min-height: 225px;
	
}

.candidate_process .right .bottom-hide {
	display: none;
}
.bottom-show a {
	text-decoration: none;
}

.candidate_process .right .bottomButtonWrapper {
	text-decoration: none;
	display: flex;
}

.bottom-button {
	background: transparent;
	box-shadow: 0px 0px 0px transparent;
	border: 1px solid #4bace9;
	text-shadow: 0px 0px 0px transparent;

	color: #4bace9;
	margin: auto;
	width: 95%;
	height: 40px;
	border-radius: 30px;
	margin: 20px auto;
	cursor: pointer;
}

.bottom-half {
	display: inline-block;
	width: 50%;
}

.bottom-bottom {
	min-height: 100px;
	margin-bottom: 10px;
	display: flex;
}

.top-skills {
	width: 50%;
}

.top-experiences {
	width: 50%;
}

.bottom-top {
	position: relative;
	min-height: 100px;
	margin-top: 10px;
}

.progress-left {
	box-sizing: border-box;
	padding-right: 5px;
	display: inline-block;
	width: 50%;
}

.progress-right {
	box-sizing: border-box;
	padding-left: 5px;
	display: inline-block;
	width: 50%;
}

.progress-bar {
	box-sizing: border-box;
	border-radius: 30px;
	text-align: center;
	line-height: 30px;
	margin-bottom: 10px;
	height: 30px;
}

.progress-back {
	box-sizing: border-box;
	border-radius: 30px;
	background-color: rgb(201, 201, 202);
	position: relative;
	height: 30px;
	margin-bottom: 10px;
}

.progress-back p {
	position: absolute;
	/*pointer-events: none;
    z-index: 1;
    */
	font-size: 16px !important;
	color: white !important;
	margin-top: 3px !important;
	margin-left: auto !important;
	margin-right: auto !important;
	left: 0;
	right: 0;
	text-align: center;
}

/* new progress bars*/
.totalGrade{
	position: absolute;
    right: 180px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.courseGrades{
	width:60%;
}

.gradesList{
	display: flex;
	width:100%;
	margin-top: 25px;
}

.courseGrades .grade2{
	flex: 1 1 0;

}

.bold{
	font-weight: bold;
}

.gradeIconHelper {
    display: inline-block;
    height: 35px;
    vertical-align: middle;
}
.gradeIconHelper2 {
    display: inline-block;
    height: 30px;
    vertical-align: middle;
}

.courseGrades .grade2 .gradeInfo2{
	text-align: center;
	
}

.courseGrades .grade2 .gradeInfo2 h5{
	margin-top:10px;
	margin-bottom: 5px;
	font-weight: bold;
	color: black;
	font-size: 14px;
}

.courseGrades .grade2 .gradeInfo2 p{
	margin-top: 5px;
	
}

.candidate_process .right .candidateInfo{
	width:calc(40% - 20px);
	margin-left:20px;
	margin-top: 10px;
}


.candidate_process .right .candidateInfo p{
	margin-top:10px;
	margin-bottom:10px;
}

.candidate_process .right .candidateInfo h3{
	
	font-size: 16px;
}




/* skills*/
.skill{
	display: inline 
}
