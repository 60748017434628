html {
	font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

hr {
	visibility: hidden;
	margin: 0px;
}

/* Hero section ----------------- */

.hero-resources {
	background-image: url('../../assets/backgrounds/resourcesBackground.png');
	background-position: top;
	/*background-attachment: fixed;*/
	padding-top: 1px;
	padding-bottom: 1px;
	min-height: 348px;
	background-size: cover;
	text-align: center;
}

.hero-resources .container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 842px;
	margin: auto;
	margin-top: 104px;
}

.hero-resources h1 {
	margin: 0px;
	font-style: normal;
	font-weight: normal;
	font-size: 52px;
	line-height: 130.1%;
	letter-spacing: -0.01em;
	text-transform: capitalize;
	color: #ffffff;
}

.hero-resources p {
	margin: 0px;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 160%;
	/* or 35px */

	text-align: center;

	color: #ffffff;
}

.loadingScreen {
	min-height: 1500px;
	background-color: #edf1f5;
	padding: 10px;
}

/*resource container*/
.resourceContainer {
	flex: 1;
	box-sizing: border-box;
	display: flex;

	padding: 40px;
	padding-bottom: 50px;
	min-height: 528px;

	justify-content: center;
}

.resourceBlock {
	flex: 1;
	max-width: 867px;
	border-radius: 30px;
	padding: 40px;
	padding-bottom: 85px;
	position: relative;
}

.resourceBlock h1 {
	margin: 0px;
	margin-bottom: 5px;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
}

.resourceBlock .resourceHead {
	display: flex;
	justify-content: space-between;
}

.resourceBlock .resourceTags {
	margin: 5px 0px;
}

.resourceBlock p {
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	line-height: 140%;
}

.resourceButtonWrapper {
	position: absolute;
	bottom: 0;
	right: 0;
}

.resourceButtonWrapper button {
	color: white;
	width: 158px;
	cursor: pointer;
	height: 53px;
	border: 1px solid #4bace9;
	border-radius: 30px;
	background-color: #4bace9;
	font-weight: bold;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.04em;
	margin-right: 40px;
	margin-bottom: 20px;
}

.resourceImages {
	display: flex;
	justify-content: center;

	position: relative;
}

.resourceImages h6 {
	font-size: 16px;
	margin: 0px;
	margin-bottom: 5px;
	margin-left: 5px;
}

.resourceImages .articleImg {
	align-self: center;
	margin-bottom: 67.5px;
	max-width: 100%;
	height: auto;
}

.resourceImages .share {
	position: absolute;
	bottom: 0;
}

.resourceImages .share_button {
	margin-right: 1em;
}

/*Let us know ------------*/
.learnMore {
	background-image: url('../../assets/backgrounds/handshake2.png');
	background-repeat: no-repeat;
	padding-top: 1px;
	padding-bottom: 1px;
	height: 289px;
	background-size: cover;
}

.learnMore h1 {
	margin: 0px;
	text-align: center;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	line-height: 46px;
	color: #ffffff;
}

.learnMore p {
	margin-top: 12px;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 150%;
	text-align: center;

	color: rgba(255, 255, 255, 0.87);
}

.learnMore .container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.learnMore button {
	margin-top: 30px;
	color: white;
	width: 170px;
	cursor: pointer;
	border-radius: 1px;
	height: 53px;
	border: 1px solid #4bace9;
	border-radius: 30px;
	background-color: #4bace9;
	font-weight: bold;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	letter-spacing: 0.04em;
}

@media screen and (max-width: 1150px) {
	.resourceBlock .resourceHead {
		display: block;
	}

	.resourceImages {
		width: 40%;
	}
}

@media screen and (max-width: 850px) {
	.resourceImages {
		display: none;
	}
}