.videoContainer{
    min-width: 600px;
    min-height: 350px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items:center ;
    text-align: left;
}

.controlBar{
    background-color: black;
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
    justify-content: space-between;
}

.controlButton{
    margin: 0;
    font-size: 20px;
    border: none;
    background: none;
    color: white;
    display: flex;
    align-items: center;
}

.reload{
    transform: rotate(40deg);
    transition-duration: 1s;
}

.reload:hover{
    transform: rotate(120deg);
    transform: scale(1.2);
}

.left-right{
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.minosViewerLogo{
    font-size: 15px;
    margin: 0;
}

.p{
    background: none;
}
.introductionBullets{
    display: flex;
    flex-direction: column;
    width: 70%;
    text-overflow: clip;
    height: 100%;
}

.resumeItem{
    animation: fadeIn 0.5s linear;
    animation-fill-mode: backwards;
}

.size15{
    font-size: 15px;
}

.size30{
    font-size: 30px;
}

.resumeItem:start{
    opacity: 0;
}

@for $i from 1 through 5{
      .resumeItem{
          &:nth-child(#{$i}){
              animation-delay: $i * 1s;
          }
      }  
}

.profileImage{
    overflow-wrap: anywhere;
    width: 25%;
    border-radius: 2px;
    animation: fadeIn 1s linear 1s;
    animation-fill-mode: backwards;
}

@keyframes fadeIn {
    0%{
        opacity: 0;
    }

    70%{
        opacity: 0.5;
    }

    100%{
        opacity: 1;
    }
}